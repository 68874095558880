/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { CapsService } from '~/services/CapsService';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import { convertToMinTwoDecimalPlaces } from '~/utils/utils';
import { AdType } from '~/services/adform-validation';
import { Product, FormattedAdgroup, FormattedProduct, Adgroup, ProductType } from './types';
import { useClientSessionQuery } from '../useSessionPlQuery';

export const adManagerQueryKeys = {
  invalidate: () => ['adManagerData'] as const,
  all: ({ clientId }: { clientId: string }) => ['adManagerData', clientId] as const,
  single: ({ clientId, productId }: { clientId: string; productId?: number }) =>
    ['adManagerData', clientId, productId] as const,
};

async function getCampaigns(clientId?: string | number, productId?: number) {
  const data = await CapsService.getCampaigns({ clientId, productId });
  if (!data) {
    throw new Error('Error getting campaigns');
  }
  if (data && typeof data === 'object' && 'error' in data && typeof data.error === 'string') {
    throw new Error(data.error);
  }
  return data as Product[];
}

const useBaseAdManagerData = <TData = Array<FormattedProduct>>(
  productId?: number,
  select?: (data: Array<FormattedProduct>) => TData,
  enabled?: boolean,
) => {
  const { setTimedNotification } = useNotifications();
  const { data: clientId } = useClientSessionQuery((data) => data.id);
  const queryClient = useQueryClient();

  return useQuery({
    // annoying that i need to add this, but the eslint rule seems to be broken for our version
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: productId
      ? adManagerQueryKeys.single({ productId, clientId: String(clientId) })
      : adManagerQueryKeys.all({ clientId: String(clientId) }),
    queryFn: () =>
      adManagerDataQueryFn(clientId, productId).then((data) => {
        for (const product of data) {
          queryClient.setQueryData(
            adManagerQueryKeys.single({
              productId: product.productId,
              clientId: String(clientId),
            }),
            data,
          );
        }
        return data;
      }),
    select,
    staleTime: 1000 * 60 * 5, // 5min
    onError: () => {
      setTimedNotification('danger', 'Error loading campaign details. Please try again.', 5000);
    },
    enabled: clientId !== undefined && enabled !== false,
  });
};

const useAdManagerData = () => useBaseAdManagerData();

export type AdManagerData = ReturnType<typeof useAdManagerData>['data'];

export async function adManagerDataQueryFn(
  clientId: number | undefined,
  productId?: number | undefined,
) {
  const data = await getCampaigns(String(clientId), productId);

  const formattedProductData = data.map((product) => {
    return formatProduct(product);
  });
  // prefill the query cache for single product queries with data

  return formattedProductData;
}

function getCampaignType(campaign: Product): AdType {
  if (campaign.type?.startsWith('Podcast') || campaign.type === 'Video CPE') {
    return 'podcast';
  }
  if (campaign.type?.startsWith('Video')) {
    return 'video';
  }
  if (campaign.adgroups.some((adgroup) => adgroup.type === 'Display')) {
    return 'display';
  }
  if (
    campaign.adgroups.some((adgroup) => adgroup.type === '200/200 Native' && adgroup.variants) &&
    campaign.type === 'VCPM'
  ) {
    return 'canvas';
  }
  return 'native';
}

export const useAdManagerDataById = (productId?: number) => {
  return useBaseAdManagerData(
    productId,
    (data) => {
      if (!productId) {
        throw new Error('No product id provided');
      }
      const product = data.find((p) => {
        return p.productId === productId;
      });
      if (!product) {
        throw new Error('Campaign not found');
      }
      return product;
    },
    !!productId,
  );
};

export default useAdManagerData;

export const productTypeToPricingModel = {
  'Video CPM': 'Video CPM',
  'Video VCPM': 'Video VCPM',
  'Video CPE': 'Podcast CPE',
  'Podcast CPE': 'Podcast CPE',
  'Video CPCV': 'Video CPCV',
  'Video CCPM': 'Video CCPM',
  'Content Click': 'CPC',
  Click: 'CPC',
  VCPM: 'VCPM',
  VCPC: 'VCPC',
  Standard: 'CPC',
  CPM: 'CPM',
  CPA: 'CPA',
  Lead: 'Lead',
} satisfies Record<NonNullable<ProductType>, string>;

export type ProductTypeToPricingModel = typeof productTypeToPricingModel;

export function formatProduct(product: Product): FormattedProduct {
  const type = getCampaignType(product);
  return {
    adgroups: formatAdgroups(product.adgroups),
    campaignName: product.campaign_name,
    campaignCode: product.campaign_code,
    caps: formatCaps(product.caps),
    launchDate: product.launch_datetime || product.launch_date || '',
    launchDatetime: product.launch_datetime || null,
    createdDatetime: product.created_datetime || null,
    endDate: product.end_date,
    price: convertToMinTwoDecimalPlaces(product.price),
    productId: product.product_id,
    productTypeId: product.product_type_id,
    providerShort: product.provider_short,
    productStatus: product.status,
    productPromotionData: product.productPromotionData,
    targeting: {
      ...product.targeting,
      device: product.targeting.device || {
        mobile: 0,
        tablet: 0,
        computer: 0,
      },
      cohorts: product.targeting.cohorts?.map((cohort) => {
        return {
          id: cohort.id,
          name: cohort.name,
          cohortTypeId: cohort.cohort_type_id,
        };
      }),
    },
    whereCappedHuman: product.where_capped_human,
    type: product.type,
    pricingModel: productTypeToPricingModel[!product.type ? 'Standard' : product.type],
    newFormat: true,
    __type: type,
    ...(product.frequency_cap && { frequency_cap: product.frequency_cap[0] }),
    timestamp: product.timestamp,
  };
}

function formatAdgroups(adgroups: Adgroup[]): FormattedProduct['adgroups'] {
  return adgroups.map((adgroup) => {
    return {
      adgroupId: adgroup.adgroup_id,
      adgroupStatus: adgroup.status,
      trackers: formatTrackers(adgroup.trackers),
      adgroupType: adgroup.type,
      timestamp: adgroup.timestamp,
      variants: formatVariants(adgroup.variants),
      partnerSmartadsTypeId: adgroup.partner_smartads_type_id,
      applyLink: adgroup.apply_link,
      description: adgroup.description,
    };
  });
}

// sort O -> M -> W -> D
function formatCaps(caps: Product['caps']) {
  return caps
    .sort((a, b) => {
      const order = ['O', 'M', 'W', 'D'];
      return order.indexOf(a.cap_period) - order.indexOf(b.cap_period);
    })
    .map((cap) => {
      return {
        capAmount: cap.cap_amount,
        capPeriod: cap.cap_period,
        capRemaining: cap.cap_remaining,
        capStartDate: cap.cap_start_date,
        capType: cap.cap_type,
        clientId: cap.client_id,
        id: cap.id,
        paced: cap.paced,
        productId: cap.product_id,
        status: cap.status,
        timestamp: cap.timestamp,
        tripped: cap.tripped,
        warn: cap.warn,
        warningLevel: cap.warning_level,
        projectedCapUsagePercentage: cap.projectedCapUsagePercentage,
        projection: cap.projection,
        capUsagePercentage: cap.capUsagePercentage,
        trend: cap.trend,
      };
    });
}

function formatTrackers(trackers: Adgroup['trackers']): FormattedAdgroup['trackers'] {
  return {
    blockingTag: trackers.blocking_tag,
    trackingPixelUrl: trackers.tracking_pixel_url,
    trackingPixelUrl2: trackers.tracking_pixel_url_2,
    trackingPixelUrl3: trackers.tracking_pixel_url_3,
    trackingJsUrl: trackers.tracking_js_url,
  };
}

function formatVariants(variants: Adgroup['variants']): FormattedAdgroup['variants'] {
  if (!variants) return null;
  return variants.reduce(
    (acc, value) => {
      return {
        ...acc,
        [value.id]: {
          action: value.action,
          active: value.active,
          clickUrl: value.apply_link,
          campaignCode: value.campaign_code,
          heroShot: value.hero_shot,
          images: value.images
            ? value.images?.map(({ s3_object_id, ...rest }) => ({
                ...rest,
                s3ObjectId: s3_object_id,
              }))
            : [],
          id: value.id,
          image: value.image,
          imgCrop: value.img_crop,
          line1: value.line1,
          line2: value.line2,
          providerShort: value.provider_short,
          selfserveApproved: value.selfserve_approved,
          serviceAdgroupId: value.service_adgroup_id,
          timestamp: value.timestamp,
          variantType: value.variant_type,
          version: value.version,
          videoUrl: value.video_url,
          videoFile: null,
          trackingPixelUrl: value.tracking_pixel_url,
          trackingPixelUrl2: value.tracking_pixel_url_2,
          trackingPixelUrl3: value.tracking_pixel_url_3,
          trackingJsUrl: value.tracking_js_url,
          displayHTML: value.display_html,
        },
      };
    },
    {} as FormattedAdgroup['variants'],
  );
}
