import { perms } from '~/routing/perms';
// import { Stats } from '../hooks/queries/useSessionPlQuery/types';
import { GenieNavPlData } from '../hooks/queries/useGenieNavQuery';
import { NavRoute } from '../layouts/Admin/RoutePermissions';

// Build navigation from Analytics session.pl endpoint
export function analyticsNav(analytics: GenieNavPlData) {
  const links: NavRoute[] = [];
  if (!analytics) {
    return links;
  }
  const newViews: NavRoute[] = Object.values(analytics).map((stat) => {
    return {
      hidden: !!stat.linked,
      path: `/analytics/${stat.shortcode}`,
      name: stat.name,
      perm: perms.analytics,
      live: true,
      paramsToKeep: ['from', 'to', 'period', 'campaign'],
    } satisfies NavRoute;
  });
  return [...newViews, ...links].sort((a, b) => (a.name > b.name ? 1 : -1));
}
