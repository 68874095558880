import { Theme } from '@mui/material';
import { mqFromDesktop } from '~/variables/breakpoints';

const ActionConfirmDialogStyles = {
  actions: {
    margin: 'auto',
    padding: '20px',
  },
  content: {
    padding: '15px',
    margin: 0,
    [mqFromDesktop]: {
      minWidth: '600px',
    },
    minWidth: '100%',
  },
  titleWrapper: {
    position: 'relative',
    borderBottom: '1px solid #DAE2EA',
    fontWeight: 600,
    textTransform: 'uppercase',
    margin: 0,
    display: 'flex',
    fontSize: '1.5em',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {},

  acceptButton: {
    '&:hover': {
      backgroundColor: (theme: Theme) => theme.palette?.info.light,
    },
  },
} as const;

export default ActionConfirmDialogStyles;
