import React from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';

const CheckboxSkeleton = (props: SkeletonProps) => {
  const { sx } = props;
  return (
    <Skeleton
      variant="circular"
      data-testid="loading-skeleton"
      sx={{
        ...sx,
      }}
    />
  );
};

export default CheckboxSkeleton;
