import { customFetch } from '~/utils/customFetch';

export async function logout() {
  try {
    const result = await customFetch.get(`/logout.pl`);
    return result.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function login(formData) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const result = await customFetch.post(`/login.pl`, JSON.stringify(formData), requestOptions);
    return result.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function passwordlessLogin({ uuid }) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const result = await customFetch.post(
    `/passwordless_login.pl`,
    JSON.stringify({ uuid }),
    requestOptions,
  );
  if (result?.data) {
    if (result?.data?.success) {
      return { success: result.data.success };
    }
  }
  return { error: result?.data?.error || 'Unknown Error' };
}

export async function updatePassword({ password, confirmPassword, uuid }) {
  if (!password || !confirmPassword) {
    return { error: 'Password and Confirm Password are required' };
  }
  if (password !== confirmPassword) {
    return { error: 'Passwords do not match' };
  }
  if (password.toLowerCase() === password) {
    return { error: 'Passwords must include at least 1 uppercase character' };
  }
  if (!password.match(/(?=.*\d)/)) {
    return { error: 'Passwords must include at least 1 number' };
  }
  if (password.length < 12) {
    return { error: 'Password must be at least 12 characters' };
  }
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const result = await customFetch.post(
    `/password_reset.pl`,
    JSON.stringify({ password, confirm_password: confirmPassword, uuid }),
    requestOptions,
  );
  return result.data;
}

export async function sendPasswordResetEmail({ username, origin }) {
  if (!username) {
    return { error: 'Username is required' };
  }

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const result = await customFetch.post(
    `/password_reset.pl`,
    JSON.stringify({ username, origin }),
    requestOptions,
  );
  return result.data;
}

export async function checkLoginType({ username }, referer) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const postData = { username };
  if (referer) {
    postData.referer = encodeURIComponent(referer);
  }
  try {
    const result = await customFetch.post(
      `/check_login_type.pl`,
      JSON.stringify(postData),
      requestOptions,
    );
    if (result?.data) {
      if (result?.data?.success) {
        return { success: result.data.success };
      }
      if (result?.data?.login_type) {
        return { loginType: result.data.login_type };
      }
    }

    return result.data;
  } catch (error) {
    return { error: error.message };
  }
}
