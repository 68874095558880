import { CreateCustomFetcher } from './cross-env-utils';
import { SELFSERVE_URL, API_BASE_PATH } from './utils';

let csrfToken = '';

function setCSRFToken(token: string) {
  csrfToken = token;
  const sessionPlTracker = JSON.parse(localStorage.getItem('sessionPlTracker') ?? '0');
  localStorage.setItem('sessionPlTracker', JSON.stringify(sessionPlTracker ? 0 : 1));
  window.dispatchEvent(new Event('storage'));
}

function getCSRFToken() {
  return csrfToken;
}

export const customFetch = CreateCustomFetcher({
  setToken: setCSRFToken,
  basePath: API_BASE_PATH,
  selfServeURL: SELFSERVE_URL,
  getCSRFToken,
  fetchOrigin: window.location.origin,
});
