/* eslint-disable no-use-before-define */
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import * as Sentry from '@sentry/react';
import { GenieNavPlData } from '~/hooks/queries/useGenieNavQuery';
import { perms } from '~/routing/perms';
import { SessionPlQueryReturnData } from '~/services/ClientServiceTS';

export type Perms = (typeof perms)[keyof typeof perms];

type BaseViews = {
  viewsProp?: undefined;
  views?: NavRoute[];
};

type InsightsViews = {
  viewsProp: 'insightsNav';
  views: (insightsRoutes: SessionPlQueryReturnData['insights_nav']) => NavRoute[];
};

type AnalyticsViews = {
  viewsProp: 'analytics';
  views: (analytics: GenieNavPlData) => NavRoute[];
};

type BaseNavRoute = {
  path: string;
  name: string;
  icon?:
    | React.ReactNode
    | (OverridableComponent<SvgIconTypeMap<string, 'svg'>> & { muiName: string });
  perm: Perms;
  live: boolean;
  maintainParam?: boolean;
  collapse?: boolean;
  exact?: boolean;
  state?: unknown;
  accountType?: string;
  allowed?: boolean;
  hidden?: boolean;
  paramsToKeep?: string[];
};

export type NavRoute = BaseNavRoute & (BaseViews | InsightsViews | AnalyticsViews);

type CollatedClientInfo = SessionPlQueryReturnData & {
  emptyAccounts: boolean;
  genieNav?: GenieNavPlData;
  error?: string;
};

// Used to add additional data to routes, determines which routes to be displayed
export const setRoutesPermissions = (
  processedRoutes: NavRoute[],
  clientInfo: CollatedClientInfo,
) => {
  if (!clientInfo || clientInfo?.emptyAccounts) {
    return [];
  }

  if (clientInfo.error) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to setRoutePermissions',
        error: clientInfo?.error,
        file: 'admin.jsx',
        fn: 'setRoutePermissions()-01',
      });
      Sentry.captureException(new Error('setRoutesPermissions()'));
    });
  }

  return processedRoutes.map((route) => {
    const updatedRoute = { ...route };
    if (route.collapse && route.views) {
      let newViews = route.views;
      if (typeof route.views === 'function') {
        if (route.viewsProp === 'analytics' && clientInfo.genieNav) {
          newViews = route.views(clientInfo.genieNav);
        } else if (route.viewsProp === 'insightsNav') {
          newViews = route.views(clientInfo.insights_nav);
        } else {
          // We are handling a callback function to get data dynamically
          newViews = [];
        }
      }
      if (typeof newViews !== 'function') {
        updatedRoute.views = setRoutesPermissions(newViews, clientInfo);
      }
    }
    updatedRoute.allowed = updatedRoute.perm({ sessionPlData: clientInfo });
    return updatedRoute;
  });
};

// Determines the current active route
export const getActiveRoute = (processedRoutes: NavRoute[]): string => {
  const defaultRoute = 'Dianomi';

  const findActiveRoute = (routes: NavRoute[]): string => {
    for (const route of routes) {
      if (route.collapse && Array.isArray(route.views)) {
        const collapseActiveRoute = findActiveRoute(route.views);
        if (collapseActiveRoute) return collapseActiveRoute;
      } else if (route.path && route.name && window.location.href.includes(route.path)) {
        return route.name;
      }
    }
    return defaultRoute;
  };

  return findActiveRoute(processedRoutes);
};
