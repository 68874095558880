import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { useTheme } from '@mui/material';
import styles from './styles';

function AutoSuggest({ items, onSelectedItem, placeholder, includeParent }, ref) {
  const theme = useTheme();
  const [suggestions, setSuggestions] = React.useState([]);
  const [value, setValue] = React.useState('');
  // eslint-disable-next-line no-shadow
  const onSuggestionsFetchRequested = ({ value }) => {
    const newSuggestions = [];
    items.forEach((item) => {
      if (item) {
        if (item.children && item.children.length > 0) {
          item.children.forEach((childItem) => {
            if (childItem.name.toLowerCase().includes(value.toLowerCase())) {
              newSuggestions.push({
                parentName: item.name,
                parentId: item.id,
                ...childItem,
              });
            }
          });
          if (includeParent) {
            if (item.name.toLowerCase().includes(value.toLowerCase())) {
              newSuggestions.push({
                ...item,
              });
            }
          }
        } else if (item.name.toLowerCase().includes(value.toLowerCase())) {
          newSuggestions.push({
            ...item,
          });
        }
      }
    });
    setSuggestions(newSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) =>
    `${suggestion.parentName ? `${suggestion.parentName} -> ` : ``}${suggestion.name}`;

  const renderSuggestion = (suggestion) => {
    let prefix = '';
    let output = '';
    if (suggestion.parentName) {
      prefix = (
        <span>
          {suggestion.parentName}
          &nbsp;-&gt;&nbsp;
        </span>
      );
    }
    output = (
      <div style={{ fontSize: '12px' }}>
        {prefix}
        {suggestion.name}
      </div>
    );
    return output;
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (event, item) => {
    const { suggestion } = item;
    onSelectedItem(suggestion.id, suggestion.name, suggestion.parentId);
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    style: {
      width: '100%',
      padding: '6px 10px',
      fontFamily: 'Nexa, Arial, sans-serif',
    },
    ref,
    autoFocus: true,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={inputProps}
      highlightFirstSuggestion
      theme={{
        ...styles,
        suggestionsList: { ...styles.suggestionsList, border: theme?.defaultStyles?.border },
        suggestionHighlighted: {
          ...styles.suggestionHighlighted,
          backgroundColor: theme?.palette?.grey[300],
        },
      }}
    />
  );
}

AutoSuggest.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  onSelectedItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  includeParent: PropTypes.bool,
};

AutoSuggest.defaultProps = {
  placeholder: '',
  includeParent: false,
};

export default React.forwardRef(AutoSuggest);
