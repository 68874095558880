import { Box, keyframes, useTheme } from '@mui/material';

import * as React from 'react';
import PropTypes from 'prop-types';

import useForkRef from '~/hooks/useForkRef';
import useWindowSize from '~/hooks/useWindowSize';

const directions = ['top', 'bottom', 'left', 'right'] as const;

type Direction = (typeof directions)[number];

function randomDirection() {
  const randomIndex = Math.floor(Math.random() * directions.length);
  return directions[randomIndex];
}

type BaseProps = {
  children: React.ReactElement;
  direction?: Direction | null;
  strokeWidth?: number | null;
  greyOutOutside?: boolean;
};
type NoAnimationProps = BaseProps & {
  animate?: false;
};
type AnimationProps = BaseProps & {
  animate: true;
  animationType?: 'inAndOut' | 'inOnly';
  animationDuration?: number | null;
};

/**
 * This component is to style it's child component with the classic dianomi box style.
 * The Parent element **MUST** have a position of relative.
 *
 * So much so that this component
 * will set the parent element's position to relative regardless of what it's already set to.
 *
 *
 * The parent needs space between it and it's child component for the lines to make sense, basically some padding.
 */
const DianomiStyleWrapper = React.forwardRef(
  (props: NoAnimationProps | AnimationProps, ref: React.ForwardedRef<HTMLElement>) => {
    const { children, direction = undefined, animate } = props;
    const [size, setSize] = React.useState({ width: 100, height: 100 });
    const [pathD, setPathD] = React.useState('M0,33 66,33 66,66 33,66 33,0');
    const { innerWidth, innerHeight } = useWindowSize();
    const [styleDirection] = React.useState(() => direction ?? randomDirection());
    const theme = useTheme();

    const innerRef = React.useRef<HTMLDivElement>(null);
    const outerRef = React.useRef<SVGSVGElement>(null);
    const [pathLength, setPathLength] = React.useState(0);

    const child = React.Children.only(children);

    if (!('ref' in child)) {
      throw new Error('The child of DianomiStyleWrapper must be able to take a ref');
    }
    // @ts-expect-error - we are enforcing the child to have be able to take a ref as it's required
    // for this component to work
    const mergedRefs = useForkRef(innerRef, child.ref, ref);
    React.useEffect(() => {
      const innerDiv = innerRef.current;
      const outerDiv = outerRef.current;
      const svgParent = outerDiv?.parentElement;
      if (!innerDiv || !outerDiv || !svgParent) return () => null;

      function setPathListener() {
        if (!innerDiv || !outerDiv || !svgParent) return;
        const { clientHeight, clientWidth } = svgParent;
        outerDiv.parentElement.style.position = 'relative';
        setSize({ width: clientWidth, height: clientHeight });

        const parentWidth = clientWidth;

        const parentHeight = clientHeight;

        const left = innerDiv.offsetLeft;
        const top = innerDiv.offsetTop;
        const width = innerDiv.clientWidth;
        const height = innerDiv.clientHeight;

        let newPathD = '';

        const right = left + width;
        const bottom = top + height;
        switch (styleDirection) {
          case 'right':
            newPathD = `M${right},0 ${right},${bottom} ${left},${bottom} ${left},${top} ${parentWidth},${top}`;
            break;
          case 'bottom':
            newPathD = `M${right},${parentHeight} ${right},${top} ${left},${top} ${left},${bottom} ${parentWidth},${bottom}`;
            break;
          case 'left':
            newPathD = `M${left},${parentHeight} ${left},${top} ${right},${top} ${right},${bottom} 0,${bottom}`;
            break;
          case 'top':
          default:
            newPathD = `M0,${top} ${right},${top} ${right},${bottom} ${left},${bottom} ${left},0`;
            break;
        }

        setPathD(newPathD);
      }

      setPathListener();
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === innerDiv) {
            setPathListener();
          }
        }
      });
      resizeObserver.observe(innerDiv);
      return () => {
        resizeObserver.unobserve(innerDiv);
      };
    }, [innerWidth, innerHeight, styleDirection]);

    const inAndOutEffect = keyframes`
    0%,
    10% {
      stroke-dashoffset: ${pathLength};
    }
    40%, 
    60% {
      stroke-dashoffset: 0;
    }
    90%,
    100% {
      stroke-dashoffset: -${pathLength};
    }
  `;

    const inOnlyEffect = keyframes`
    0%, 
    10% {
      stroke-dashoffset: ${pathLength};
    }
    90%, 
    100% {
      stroke-dashoffset: 0;
    }
    `;

    const fadeInBackground = keyframes`
  0% {
    background-color: rgba(128, 128, 128, 0);
  }
  100% {
    background-color: rgba(128, 128, 128, 1);
  }
`;

    const animationTypes = {
      inAndOut: inAndOutEffect,
      inOnly: inOnlyEffect,
    };

    const animationTimingFunction = {
      inAndOut: 'ease-in-out',
      inOnly: 'ease-in-out',
    };

    const animationLoop = {
      inAndOut: 'infinite',
      inOnly: 'forwards',
    };
    const animationType = props.animate && props.animationType ? props.animationType : 'inOnly';

    const animation = props.animate ? animationTypes[animationType] : '';
    const animationDuration = props.animate ? props.animationDuration ?? 1.5 : '';
    const animationTiming = props.animate ? animationTimingFunction[animationType] : '';
    const animationIteration = props.animate ? animationLoop[animationType] : '';

    const animationString = `${animation} ${animationDuration}s ${animationTiming} ${animationIteration}`;

    const shouldFadeInBackground =
      props.greyOutOutside && props.animate && animationType === 'inOnly';

    return (
      <>
        {props.greyOutOutside && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
              '::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                ...(props.greyOutOutside &&
                  !animation && {
                    backgroundColor: 'rgba(128, 128, 128, 1)',
                  }),
                zIndex: -1,
                ...(shouldFadeInBackground && {
                  animation: `${fadeInBackground} ${
                    (props.animationDuration ?? 1.5) / 3
                  }s ease-out ${((props.animationDuration ?? 1.5) / 3) * 2}s forwards`,
                }),
              },
              '::after': {
                content: '""',
                position: 'absolute',
                zIndex: 0,
                backgroundColor: 'rgba(255, 255, 255, 1)',
                clipPath: 'url(#dianomi-clip)',
              },
            }}
          />
        )}
        <Box
          component="svg"
          ref={outerRef}
          width={size.width}
          height={size.height}
          viewBox={`0 0 ${size.width} ${size.height}`}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
        >
          <defs>
            <clipPath id="dianomi-clip">
              <path d={pathD} />
            </clipPath>
          </defs>
          <Box
            component="path"
            sx={
              animate
                ? {
                    animation: animationString,
                    strokeDasharray: `${pathLength}`,
                    strokeDashoffset: `${pathLength}`,
                  }
                : {}
            }
            id="overlapping-path"
            d={pathD}
            stroke={props.greyOutOutside ? theme.palette.primary.main : theme.palette.grey[700]}
            ref={(el: SVGPathElement) => {
              if (el && el.getTotalLength) {
                setPathLength(el.getTotalLength());
              } else {
                setPathLength(0);
              }
            }}
            strokeWidth={props.strokeWidth ?? 2}
            fill="none"
            vectorEffect="non-scaling-stroke"
          />
        </Box>
        {React.cloneElement(child, {
          ref: mergedRefs,
        })}
      </>
    );
  },
);

/**
 * This is all stolen straight from MUI
 * This is all because you can't for certain tell that the child component will take a ref, so we
 * have to check it like this
 */

function chainPropTypes<A, B>(
  propType1: PropTypes.Validator<A>,
  propType2: PropTypes.Validator<B>,
): PropTypes.Validator<A & B> {
  if (process.env.NODE_ENV === 'production') {
    return () => null;
  }

  return function validate(...args) {
    return propType1(...args) || propType2(...args);
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
function isClassComponent(elementType: Function) {
  // elementType.prototype?.isReactComponent
  const { prototype = {} } = elementType;

  return Boolean(prototype.isReactComponent);
}

function acceptingRef(
  props: { [key: string]: unknown },
  propName: string,
  componentName: string,
  location: string,
  propFullName: string,
) {
  const element = props[propName];
  const safePropName = propFullName || propName;

  if (
    element == null ||
    // When server-side rendering React doesn't warn either.
    // This is not an accurate check for SSR.
    // This is only in place for Emotion compat.
    // TODO: Revisit once https://github.com/facebook/react/issues/20047 is resolved.
    typeof window === 'undefined'
  ) {
    return null;
  }

  let warningHint;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const elementType: unknown = (element as any).type;
  /**
   * Blacklisting instead of whitelisting
   *
   * Blacklisting will miss some components, such as React.Fragment. Those will at least
   * trigger a warning in React.
   * We can't whitelist because there is no safe way to detect React.forwardRef
   * or class components. "Safe" means there's no public API.
   *
   */
  if (typeof elementType === 'function' && !isClassComponent(elementType)) {
    warningHint = 'Did you accidentally use a plain function component for an element instead?';
  }

  if (warningHint !== undefined) {
    return new Error(
      `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. ` +
        `Expected an element that can hold a ref. ${warningHint} ` +
        'For more information see https://mui.com/r/caveat-with-refs-guide',
    );
  }

  return null;
}

const elementAcceptingRef = chainPropTypes(
  PropTypes.element,
  acceptingRef,
) as PropTypes.Requireable<React.ReactElement>;
elementAcceptingRef.isRequired = chainPropTypes(PropTypes.element.isRequired, acceptingRef);

DianomiStyleWrapper.propTypes = {
  children: elementAcceptingRef.isRequired,
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left', null, undefined]),
  strokeWidth: PropTypes.number,
  greyOutOutside: PropTypes.bool,
};

DianomiStyleWrapper.defaultProps = {
  direction: undefined,
  strokeWidth: 2,
  greyOutOutside: false,
};

export default DianomiStyleWrapper;
