import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SessionPlQueryReturnData, handleGetClientSessionInfo } from '~/services/ClientServiceTS';
import { getAccountDetailsFromURL } from '~/utils/utils';

export function getSessionPlQueryKey({
  accountId,
  accountType,
}: {
  accountId?: string;
  accountType?: string;
}) {
  return ['session.pl', accountId, accountType] as const;
}

const useSessionPlQuery = <TData = SessionPlQueryReturnData>(
  select?: (data: SessionPlQueryReturnData) => TData,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { accountId, accountType } =
    getAccountDetailsFromURL(searchParams.get('referer') || location.pathname) || {};
  return useQuery({
    queryKey: getSessionPlQueryKey({ accountId, accountType }),
    queryFn: async () => {
      const data = await handleGetClientSessionInfo(null, {
        accountId,
        accountType,
      });
      if (accountId && accountId !== data?.id?.toString()) {
        navigate(`/${data?.isPartner ? 'partner' : 'client'}/${data?.id}/dashboard`);
      }

      if (data?.login?.csrf_token) {
        const copyData = { ...data };
        delete copyData?.login?.csrf_token;
        return copyData;
      }
      return data;
    },
    select,
    keepPreviousData: true,
    // only do the refetching when the actual path has accountId in it, not when search params have it in
    refetchOnMount: !!getAccountDetailsFromURL(location.pathname)?.accountId,
    refetchOnWindowFocus: !!getAccountDetailsFromURL(location.pathname)?.accountId,
    // staleTime: 1000 * 60 * 30, // 30 minutes stale time
    retry: 0,
  });
};

export type ResolvedClientSessionData = Exclude<
  Awaited<ReturnType<typeof handleGetClientSessionInfo>>,
  { isPartner: true }
>;

export type ResolvedPartnerSessionData = Exclude<
  Awaited<ReturnType<typeof handleGetClientSessionInfo>>,
  { isPartner: false }
>;

/**
 * # For Client (Advertiser) use only
 *
 * It will throw an error if you try to use it when you're logged in as a partner.
 *
 * If you want to use it in a partner component, use `usePartnerSessionQuery` instead (or `useSessionPlQuery` which is for both Client and Partner components)
 */
export function useClientSessionQuery(): UseQueryResult<ResolvedClientSessionData>;
export function useClientSessionQuery<TData = ResolvedClientSessionData>(
  select?: (data: ResolvedClientSessionData) => TData,
): UseQueryResult<TData>;
export function useClientSessionQuery<TData = ResolvedClientSessionData>(
  select?: (data: ResolvedClientSessionData) => TData,
): UseQueryResult<TData | ResolvedClientSessionData> {
  return useSessionPlQuery((data) => {
    if (data.isPartner)
      throw new Error(
        `useClientSessionQuery hook is for client pages only, and you're requesting it as a partner. Either use the partner version or useSessionPlQuery which is both`,
      );

    if (select) return select(data);

    return data;
  });
}

/**
 * # For Partner (Publisher) use only
 *
 *
 * It will throw an error if you try to use it when you're logged in as a client.
 *
 *
 * If you want to use it in a client component, use `useClientSessionQuery` instead (or `useSessionPlQuery` which is for both Client and Partner components)
 */
export function usePartnerSessionQuery(): UseQueryResult<ResolvedPartnerSessionData>;
export function usePartnerSessionQuery<TData = ResolvedPartnerSessionData>(
  select?: (data: ResolvedPartnerSessionData) => TData,
): UseQueryResult<TData>;
export function usePartnerSessionQuery<TData = ResolvedPartnerSessionData>(
  select?: (data: ResolvedPartnerSessionData) => TData,
): UseQueryResult<TData | ResolvedPartnerSessionData> {
  return useSessionPlQuery((data) => {
    if (!data.isPartner)
      throw new Error(
        "usePartnerSessionQuery  hook is for partner pages only, and you're requesting it as a client. Either use the client version or useSessionPlQuery which is both",
      );

    if (select) return select(data);
    return data;
  });
}

export default useSessionPlQuery;
