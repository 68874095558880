import { Params, useParams } from 'react-router-dom';

const validateAccountParams = (params: Readonly<Params<string>>) => {
  if (!params.accountType || !params.accountId) {
    return null;
  }
  if (!params.accountType.match(/client|partner/i)) {
    return null;
  }
  // ensure accountId is a number
  if (!params.accountId.match(/^\d+$/)) {
    return null;
  }
  return { accountType: params.accountType, accountId: Number(params.accountId) };
};

export function useAccountParams() {
  const params = useParams();
  const accountDetails = validateAccountParams(params);

  return accountDetails;
}
