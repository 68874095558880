import React from 'react';
import { Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import useWindowSize from '~/hooks/useWindowSize';
import useAccountsQuery from '~/hooks/queries/useAccountsQuery';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import useLoginSeenCookie from '~/hooks/useLoginSeenCookie';
import DianomiStyleWrapper from '~/components/Commons/DianomiStyleWrapper/DianomiStyleWrapper';
import DianomiSpinner from '~/components/Commons/DianomiSpinner/DianomiSpinner';
import DashboardContainer from '~/components/Commons/DashboardContainer';
import SectionTitle from '~/components/Typography/SectionTitle';
import NoSessionPage from '~/views/Pages/NoSessionPage';
import LoadingProgress from '~/components/Commons/LoadingProgress';
import ClientsSelector from '../ClientsSelector/ClientsSelector';

function OnLoginClientsSelector() {
  const { data: sessionData, isInitialLoading: isSessionLoading } = useSessionPlQuery();
  const { data: accountsData, isInitialLoading: isAccountsLoading } = useAccountsQuery();
  const { setLoginSeenCookie } = useLoginSeenCookie();
  const { isMobile } = useWindowSize();
  const isLoading = isAccountsLoading || isSessionLoading;
  // handle the case where sessionData is defined (ie user has a session)
  // but accountsData is not (ie accounts.pl failed for some reason)
  if (!isLoading && sessionData && !accountsData) {
    const accountType = sessionData?.isPartner ? 'partner' : 'client';
    const accountId = sessionData?.id;
    return <Navigate to={`/${accountType}/${accountId}`} />;
  }
  let isSingleTabMenu: boolean;
  if (accountsData) {
    const clients = accountsData.accounts.clients.length;
    const partners = accountsData.accounts.partners.length;
    isSingleTabMenu = (clients === 0 || partners === 0) && clients + partners > 0;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
        alignSelf: 'stretch',
        width: '100%',
      }}
    >
      <DianomiSpinner loading={isLoading}>
        <DianomiStyleWrapper animate animationType="inOnly">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor: 'secondary.main',
              overflow: 'hidden',
              maxHeight: '500px',
              minHeight: 'fit-content',
              width: '80vw',
              maxWidth: '800px',
              paddingRight: isMobile ? '2px' : '0px',
              ...(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  width: isSingleTabMenu ? '40vw' : '90vw',
                },
              }),
            }}
          >
            <SectionTitle
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '10px',
                marginBottom: '0px',
                fontSize: isMobile ? '18px' : '24px',
              }}
            >
              Select an account:
            </SectionTitle>
            <Box sx={{ height: '100%', backgroundColor: 'secondary.main', width: '100%' }}>
              <ClientsSelector
                accounts={accountsData?.accounts}
                isOnLogin
                onDoneClick={setLoginSeenCookie}
              />
            </Box>
          </Box>
        </DianomiStyleWrapper>
      </DianomiSpinner>
    </Box>
  );
}

export default function OnLoginClientSelectorHandler() {
  const { data: sessionData, isInitialLoading } = useSessionPlQuery();
  const { isMobile } = useWindowSize();
  if (isInitialLoading) {
    return <LoadingProgress loading />;
  }

  return (
    <DashboardContainer
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: isMobile ? 'flex-start' : 'center',
        marginTop: isMobile ? '20vw' : 'none',
        padding: '20px',
        margin: 0,
        width: '100%',
      }}
    >
      <DianomiSpinner loading={isInitialLoading}>
        {!!sessionData && !isInitialLoading ? <OnLoginClientsSelector /> : <NoSessionPage />}
      </DianomiSpinner>
    </DashboardContainer>
  );
}
