import React from 'react';
import List from '@mui/material/List';
import styles from './styles';

type StyledListProps = {
  children?: React.ReactNode;
  collapse?: boolean;
  level?: number;
};

function StyledList({ children, collapse = false, level }: StyledListProps) {
  return (
    <List
      sx={{
        ...styles.list,
        ...(collapse && styles.collapse),
      }}
    >
      {children}
    </List>
  );
}

export default StyledList;
