/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  MaterialReactTable,
  MRT_PaginationState,
  MRT_TableState,
  MRT_Updater,
  MaterialReactTableProps,
  MRT_RowData,
} from 'material-react-table';
import TableCell from '~/views/Dashboard/Analytics/TableCell';
import { Box } from '@mui/material';

type OmittedProps<TData extends MRT_RowData> = Omit<
  Partial<MRT_TableState<TData>>,
  'isLoading' | 'showAlertBanner' | 'showProgressBars' | 'density' | 'pagination'
>;

export interface CustomState<TData extends MRT_RowData> extends OmittedProps<TData> {
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
}

export type BaseDataTableProps<TData extends MRT_RowData> = Prettify<
  Omit<MaterialReactTableProps<TData>, 'state' | 'table'>
>;

export default function DataTable<TData extends MRT_RowData>({
  data = [],
  columns,
  state,
  initialState,
  Actions = undefined,
  currency,
  formatNumbers = true,
  ...rest
}: Prettify<
  BaseDataTableProps<TData> & {
    state?: CustomState<TData>;
    Actions?: React.ReactNode;
    currency?: string;
    formatNumbers?: boolean;
  }
>) {
  const [pagination, setPagination] = React.useState(() => ({
    pageIndex: 0,
    pageSize: localStorage.getItem('tablePageSize')
      ? parseInt(localStorage.getItem('tablePageSize') ?? '100', 10)
      : 100,
  }));

  const onPaginationChange = React.useCallback((updater: MRT_Updater<MRT_PaginationState>) => {
    setPagination((prevState) => {
      if (typeof updater === 'function') {
        const newState = updater(prevState);
        if (newState.pageSize !== prevState.pageSize) {
          localStorage.setItem('tablePageSize', newState.pageSize.toString());
        }
        return newState;
      }
      return updater;
    });
  }, []);

  return (
    <MaterialReactTable
      columns={columns || []}
      data={data || []} // data is undefined on first render
      enableStickyHeader
      enableTableFooter
      enableDensityToggle={false}
      enableColumnFilterModes
      enableColumnPinning
      enableMultiSort
      enableFullScreenToggle={false}
      muiTableContainerProps={{
        sx: {
          borderRadius: '4px',
          border: '2px solid #efefef',
          marginTop: '4px',
        },
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          border: 'none',
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          '& .MuiToolbar-gutters': {
            boxShadow: 'none',
          },
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: 600,
          fontSize: '15px',
          height: '40px',
        },
      }}
      muiTopToolbarProps={{
        sx: {
          '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
            margin: '5px 0px',
          },
          '& .MuiIconButton-root[aria-label="Show/Hide filters"], & .MuiIconButton-root[aria-label="Show/Hide search"], & .MuiIconButton-root[aria-label="Show/Hide columns"]':
            {
              borderRadius: '4px',
              marginLeft: '2px',
              border: '2px solid #efefef',
            },
        },
      }}
      renderTopToolbarCustomActions={() => Actions}
      // tableInstanceRef={tableRef}
      onPaginationChange={onPaginationChange}
      renderEmptyRowsFallback={({ table }) => {
        if (data.length === 0) {
          return <Box sx={{ p: 2, textAlign: 'center' }}>No data available</Box>;
        }
        if (table.getFilteredRowModel().rows.length === 0) {
          return <Box sx={{ p: 2, textAlign: 'center' }}>No data matches current filters.</Box>;
        }
        return <Box sx={{ p: 2, textAlign: 'center' }}>No data available</Box>;
      }}
      defaultColumn={{
        Cell: ({ renderedCellValue, column, row }) => {
          let columnId = column.id;
          if (columnId.toLowerCase().includes('custom')) {
            [columnId] = columnId.split('.');
          }

          return (
            <TableCell
              value={row.original[columnId] ?? renderedCellValue}
              column={{
                header: column.columnDef.header,
                accessor: column.columnDef.accessorKey as string,
              }}
              row={row.original}
              currency={currency ?? ''}
              formatNumbers={formatNumbers}
            />
          );
        },
      }}
      state={{
        ...state,
        isLoading: state?.isLoading || false,
        showAlertBanner: state?.isError || false,
        showProgressBars: state?.isFetching || false,
        pagination,
        density: 'compact' as const,
      }}
      initialState={{
        sorting: data[0] && 'date' in data[0] ? [{ id: 'date', desc: true }] : [],
        ...initialState,
      }}
      {...rest}
    />
  );
}
