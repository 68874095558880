import * as React from 'react';

import { useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString } from '~/utils/typescriptHelpers';
import { Cohort } from '~/hooks/queries/useAdmanagerData/types';

type CohortType = {
  owner_client_id: number | null;
  cohorts: Array<Cohort>;
  owner_logo: string;
  ownerTitleText: string;
  id: `${number}`;
  name: string;
};

type ResponseData = Array<CohortType> | ErrorObjectString;

async function getCohorts() {
  const { data } = await customFetch.get<ResponseData>(`/authenticated/cohorts`, {
    headers: {
      'content-type': 'application/json',
    },
    params: {
      v2: '1',
    },
    useNodeEndpoint: true,
  });
  if ('error' in data) {
    throw new Error(data.error);
  }
  return data;
}

const useCohortQuery = () => {
  return useQuery({
    queryKey: ['cohorts'],
    queryFn: async () => getCohorts(),
    refetchOnWindowFocus: false,
  });
};

export default function JoeTest() {
  const query = useCohortQuery();

  return <pre>{JSON.stringify(query, null, 2)}</pre>;
}
