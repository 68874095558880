import { useState, useRef, useEffect } from 'react';
import * as Sentry from '@sentry/react';

function initState<T>(defaultValue: T) {
  const state = localStorage.getItem('state');
  if (state) {
    return JSON.parse(state) as T;
  }
  return defaultValue;
}

const useCrossTabState = <T>(stateKey: string, defaultValue: T) => {
  const [state, setState] = useState<T | unknown>(() => initState(defaultValue));
  const isNewSession = useRef(true);
  useEffect(() => {
    if (isNewSession.current) {
      const currentState = localStorage.getItem(stateKey);
      if (currentState) {
        setState(JSON.parse(currentState));
      } else {
        setState(defaultValue);
      }
      isNewSession.current = false;
      return;
    }
    try {
      localStorage.setItem(stateKey, JSON.stringify(state));
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: `Error trying to set LocalStorage ${stateKey}, ${JSON.stringify(state)}`,
          file: '?',
          fn: 'useCrossTabState()-01',
        });
        Sentry.captureException(error);
      });
    }
  }, [state, stateKey, defaultValue]);

  useEffect(() => {
    const onReceiveMessage = (e: StorageEvent) => {
      const valueFromStorage = JSON.parse(localStorage.getItem(stateKey) ?? '');
      setState((oldState: T | unknown) =>
        valueFromStorage === oldState ? oldState : valueFromStorage,
      );
    };
    window.addEventListener('storage', onReceiveMessage, false);
    return () => window.removeEventListener('storage', onReceiveMessage);
  }, [stateKey, setState]);

  const updateState = (newState: T) => {
    setState(newState);
    localStorage.setItem(stateKey, JSON.stringify(newState));
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(stateKey);
  };
  return [state, updateState, clearLocalStorage] as const;
};

export default useCrossTabState;
