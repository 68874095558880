import { Box } from '@mui/material';
import useClientPlQuery from '../../../hooks/queries/useClientPlQuery';

const suspensionMessages: Record<string, React.ReactNode> = {
  Dispute: (
    <>
      A Payment has been Disputed for this account. All campaigns activity has been suspended.
      <br />
      Please contact{' '}
      <a href="mailto:help@dianomi.com" target="_blank" rel="noreferrer">
        help@dianomi.com
      </a>{' '}
      to resolve the dispute.
    </>
  ),
  Arrears: (
    <>
      This account is in arrears - All campaigns activity has been suspended.
      <br />
      Please update your payment method or contact{' '}
      <a href="mailto:help@dianomi.com" target="_blank" rel="noreferrer">
        help@dianomi.com
      </a>
      .
    </>
  ),
};

const defaultSuspensionMessage = (
  <>
    Account Suspended. All campaigns activity has been stopped <br />
    Please contact{' '}
    <a href="mailto:help@dianomi.com" target="_blank" rel="noreferrer">
      help@dianomi.com
    </a>
    for more information.
  </>
);

export function AccountSuspendedIndicator() {
  const clientPlQuery = useClientPlQuery();
  if (!clientPlQuery.data) {
    return null;
  }
  if (clientPlQuery.data.suspensionReason) {
    return (
      <Box
        role="alert"
        component="h4"
        sx={{
          backgroundColor: 'error.main',
          textAlign: 'center',
          margin: 0,
          padding: 0,
          '& a': {
            color: 'primary.main',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        {suspensionMessages[clientPlQuery.data.suspensionReason] || defaultSuspensionMessage}
      </Box>
    );
  }
  return null;
}
