import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

type LoadingProgressProps = { loading: boolean };

const styles = {
  wrapper: { padding: '20px 0', margin: '0 auto' },
  icon: { display: 'block', margin: '0 auto' },
};

function LoadingProgress({ loading }: LoadingProgressProps) {
  return (
    <>
      {loading && (
        <Box sx={styles.wrapper}>
          <CircularProgress sx={styles.icon} data-testid="loading-progress" />
        </Box>
      )}
    </>
  );
}

export default LoadingProgress;
