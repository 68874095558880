/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import useMenu from '~/context/useMiniMenu';
import useWindowSize from '~/hooks/useWindowSize';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';

import NavbarClientSelector from '~/components/Clients/NavbarClientSelector';
import { Box } from '@mui/material';
import List from './List';
import SidebarLink from './Link';

import styles from './styles';

const reducer = (oldState, newState) => ({ ...oldState, ...newState });

// this verifies if any of the collapses should be default opened on a rerender of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
function getCollapseInitialState(routesForInitialState) {
  for (let i = 0; i < routesForInitialState.length; i += 1) {
    if (
      routesForInitialState[i].collapse &&
      getCollapseInitialState(routesForInitialState[i].views)
    ) {
      return true;
    }
    if (window.location.href.indexOf(routesForInitialState[i].path) !== -1) {
      return true;
    }
  }
  return false;
}
function Sidebar({ routes, basePath }) {
  const { isOpen, closeMenu } = useMenu();
  const { isMobile } = useWindowSize();
  const { data: sessionData } = useSessionPlQuery();
  const sessionAccountType = sessionData?.isPartner ? 'partner' : 'client';
  const [state, setState] = React.useReducer(reducer, {
    openAvatar: false,
    sidebarOpen: isOpen,
    ...getCollapseStates(routes),
  });
  const { sidebarOpen } = state;
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  function getCollapseStates(routesForCollapseState) {
    let initialState = {};
    routesForCollapseState.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  }

  const onCollapseLinkClick = (st) => (e) => {
    e.preventDefault();
    const status = state[st];
    setState({ [st]: !status });
  };

  const onMenuClick = () => {
    if (window.innerWidth < 960) {
      closeMenu();
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routesForLinks, level = 0) => {
    return routesForLinks.map((prop) => {
      if (prop.redirect || prop.noMenu || !prop.allowed) {
        return null;
      }
      if (prop.hidden) {
        return false;
      }
      // If route is set to run on a specific account type,
      // and does not match the account type the user is viewing, the route will not display
      if (prop.accountType && prop.accountType !== sessionAccountType) {
        return null;
      }
      return (
        <SidebarLink
          key={prop.path}
          basePath={basePath}
          prop={prop}
          sidebarOpen={sidebarOpen}
          onNavLinkClick={prop.collapse ? onCollapseLinkClick(prop.state) : onMenuClick}
          hasCaret={prop.collapse}
          isCollapseActive={state[prop.state]}
          level={level}
        >
          {prop.collapse && (
            <Collapse in={state[prop.state] && sidebarOpen} unmountOnExit>
              <List collapse level={level + 1}>
                {createLinks(prop.views, level + 1)}
              </List>
            </Collapse>
          )}
        </SidebarLink>
      );
    });
  };

  const handleHover = (onEnter) => {
    if (!isOpen) {
      setState({ sidebarOpen: onEnter });
    }
  };

  const drawerPaperStyle = {
    ...styles.drawerPaper,
    ...(!sidebarOpen && styles.drawerPaperMini),
  };

  const sidebarWrapperStyle = {
    ...styles.sidebarWrapper,
    ...(!sidebarOpen && styles.drawerPaperMini),
  };

  useEffect(() => {
    setState({ sidebarOpen: isOpen });
  }, [isOpen]);

  const links = createLinks(routes);
  return isMobile ? (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isOpen}
      sx={{
        '& .MuiDrawer-paper': { ...drawerPaperStyle, ...styles.blackBackground },
      }}
      onClose={closeMenu}
      ModalProps={{
        keepMounted: false, // Prevents nav being visible on load. Change to true if we need better open performance on mobile.
        BackdropProps: {
          style: {
            opacity: 0,
          },
        },
      }}
    >
      <Box sx={sidebarWrapperStyle}>
        <NavbarClientSelector />
        <List>{links}</List>
      </Box>
    </Drawer>
  ) : (
    <div onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
      <Drawer
        anchor="left"
        variant="permanent"
        open
        sx={{
          '& .MuiDrawer-paper': { ...drawerPaperStyle, ...styles.blackBackground },
        }}
      >
        <Box sx={sidebarWrapperStyle}>
          <List>{links}</List>
        </Box>
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  basePath: PropTypes.string.isRequired,
};

export default Sidebar;
