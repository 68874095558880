const appStyle = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      "header header"
      "sidebar main"
    `,
    height: '100vh',
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    top: '0',
    overflow: 'auto',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  map: {
    marginTop: '70px',
  },
  mainPanelSidebarMini: (theme) => ({
    [theme.breakpoints?.up('sm')]: {
      width: `calc(100% - ${theme.defaultStyles?.drawerMiniWidth}px)`,
      '@media (max-width: 960px)': {
        width: '100%',
      },
    },
  }),
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  mainPanel: (theme) => ({
    backgroundColor: theme.palette?.secondary.main,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    transition: theme.defaultStyles?.transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
};

export default appStyle;
