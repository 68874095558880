import { useEffect, useRef, useState, useCallback } from 'react';

const timeout = (func, time) => setTimeout(() => func(), time);

const useTimeoutToggler = (time) => {
  const [isTimedOut, setIsTimedOut] = useState(false);

  const toggleTimeout = useRef(null);

  const resetTimer = useCallback(() => {
    clearTimeout(toggleTimeout.current);
    setIsTimedOut(false);
    toggleTimeout.current = timeout(() => setIsTimedOut(true), time);
  }, [time]);

  useEffect(() => {
    toggleTimeout.current = timeout(() => setIsTimedOut(true), time);
    return () => clearTimeout(toggleTimeout.current);
  }, [toggleTimeout, time]);

  return [isTimedOut, resetTimer];
};

export default useTimeoutToggler;
