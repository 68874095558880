import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface PageHeaderTitleProps extends TypographyProps {
  children: React.ReactNode;
}

const styles = {
  pageHeaderTitle: {
    fontWeight: '600',
    color: (theme: Theme) => theme.palette?.primary.main,
    margin: 0,
    padding: '0px 5px',
    width: '100%',
  },
};

function PageHeaderTitle({ children }: PageHeaderTitleProps) {
  return (
    <Typography variant="h1" sx={styles.pageHeaderTitle}>
      {children}
    </Typography>
  );
}

export default PageHeaderTitle;
