import React from 'react';
import PropTypes from 'prop-types';

import AccountWhite from '~/assets/img/icons/white/Account.png';
import AdManagerWhite from '~/assets/img/icons/white/AdManager.png';
import AnalyticsWhite from '~/assets/img/icons/white/Analytics.png';
import AudienceWhite from '~/assets/img/icons/white/Audience.png';
import BillingWhite from '~/assets/img/icons/white/Billing.png';
import NewAdWhite from '~/assets/img/icons/white/NewAd.png';
import OverviewWhite from '~/assets/img/icons/white/Overview.png';
import PubBidManagerWhite from '~/assets/img/icons/white/PubBidManager.png';
import DeviceBidManagerWhite from '~/assets/img/icons/white/DeviceBidManager.png';
import CaseStudyWhite from '~/assets/img/icons/white/CaseStudy.png';

import AccountBlack from '~/assets/img/icons/black/Account.png';
import AdManagerBlack from '~/assets/img/icons/black/AdManager.png';
import PubBidManagerBlack from '~/assets/img/icons/black/PubBidManager.png';
import DeviceBidManagerBlack from '~/assets/img/icons/black/DeviceBidManager.png';
import AnalyticsBlack from '~/assets/img/icons/black/Analytics.png';
import AudienceBlack from '~/assets/img/icons/black/Audience.png';
import BillingBlack from '~/assets/img/icons/black/Billing.png';
import NewAdBlack from '~/assets/img/icons/black/NewAd.png';
import OverviewBlack from '~/assets/img/icons/black/Overview.png';
import CaseStudyBlack from '~/assets/img/icons/black/CaseStudy.png';
import { Box } from '@mui/material';
import { NavRoute } from '~/layouts/Admin/RoutePermissions';

import styles from './styles';

type SidebarItemIconProps = {
  prop?: Partial<NavRoute>;
  active?: string;
  hover?: boolean;
};

function SidebarItemIcon({ prop, active, hover }: SidebarItemIconProps) {
  if (!prop) {
    return null;
  }
  const { icon } = prop;
  let iconPng;
  let iconPngHover;

  switch (icon) {
    case 'Account':
      iconPng = active ? AccountBlack : AccountWhite;
      iconPngHover = !active ? AccountBlack : AccountWhite;
      break;
    case 'AdManager':
      iconPng = active ? AdManagerBlack : AdManagerWhite;
      iconPngHover = !active ? AdManagerBlack : AdManagerWhite;
      break;
    case 'PubBidManager':
      iconPng = active ? PubBidManagerBlack : PubBidManagerWhite;
      iconPngHover = !active ? PubBidManagerBlack : PubBidManagerWhite;
      break;
    case 'DeviceBidManager':
      iconPng = active ? DeviceBidManagerBlack : DeviceBidManagerWhite;
      iconPngHover = !active ? DeviceBidManagerBlack : DeviceBidManagerWhite;
      break;
    case 'Analytics':
      iconPng = active ? AnalyticsBlack : AnalyticsWhite;
      iconPngHover = !active ? AnalyticsBlack : AnalyticsWhite;
      break;
    case 'Insights':
      iconPng = active ? AudienceBlack : AudienceWhite;
      iconPngHover = !active ? AudienceBlack : AudienceWhite;
      break;
    case 'Billing':
      iconPng = active ? BillingBlack : BillingWhite;
      iconPngHover = !active ? BillingBlack : BillingWhite;
      break;
    case 'NewAd':
      iconPng = active ? NewAdBlack : NewAdWhite;
      iconPngHover = !active ? NewAdBlack : NewAdWhite;
      break;
    case 'Overview':
      iconPng = active ? OverviewBlack : OverviewWhite;
      iconPngHover = !active ? OverviewBlack : OverviewWhite;
      break;
    case 'CaseStudy':
      iconPng = active ? CaseStudyBlack : CaseStudyWhite;
      iconPngHover = !active ? CaseStudyBlack : CaseStudyWhite;
      break;
    case 'AutomatedReporting':
      iconPng = active ? AdManagerBlack : AdManagerWhite;
      iconPngHover = !active ? AdManagerBlack : AdManagerWhite;
      break;
    default:
      iconPng = active ? OverviewBlack : OverviewWhite;
      iconPngHover = !active ? OverviewBlack : OverviewWhite;
  }

  let itemImgSrc = iconPng;
  if (!active && hover) {
    itemImgSrc = iconPngHover;
  }

  if (!icon && typeof icon !== 'string') {
    return null;
  }

  return (
    <div>
      <Box component="img" sx={styles.itemIcon} src={itemImgSrc} alt="Entry menu icon" />
    </div>
  );
}

SidebarItemIcon.propTypes = {
  prop: PropTypes.instanceOf(Object),
  active: PropTypes.string,
  hover: PropTypes.bool,
};
SidebarItemIcon.defaultProps = {
  prop: null,
  active: null,
  hover: null,
};

export default SidebarItemIcon;
