import * as React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import HandleRedirects from '../HandleRedirects';

export default function SessionHandler() {
  const { accountId, accountType } = useParams();
  const sessionPlQuery = useSessionPlQuery();
  // this is to handle the case of user changing the url
  // manually to an invalid url
  if (accountType === 'admin') {
    return <HandleRedirects />;
  }

  return sessionPlQuery.data?.id.toString() === accountId &&
    sessionPlQuery.data?.accountType === accountType ? (
    <Outlet />
  ) : null;
}
