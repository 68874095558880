import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import { useLocation } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';
import SidebarItemIcon from '../ItemIcon';
import styles from './styles';
import { CustomNavLink } from '../../Link/Link';

/**
 *
 * @param {string[] | undefined} paramsToKeep
 * @param {string} searchString
 * @returns
 */
function keepParams(paramsToKeep, searchString) {
  if (!paramsToKeep || !searchString) return '';
  const searchParams = new URLSearchParams(searchString);
  const newSearchParams = new URLSearchParams();
  // add the params to keep, including duplicated ones, e,g, ?a=1&a=2
  paramsToKeep.forEach((param) => {
    searchParams.getAll(param).forEach((value) => {
      newSearchParams.append(param, value);
    });
  });
  return `?${newSearchParams.toString()}`;
}

const activeRoute = (routeName, level, hasChildren) => {
  if (level > 0 && !hasChildren) {
    const url = window.location.href.split('?');
    return url[0].endsWith(routeName) ? 'active' : '';
  }
  const x = window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  return x;
};

function Link({
  children,
  hasCaret,
  isCollapseActive = false,
  prop,
  sidebarOpen,
  onNavLinkClick,
  level = 0,
  basePath = '',
}) {
  const location = useLocation();
  const hasChildren = !!React.Children.count(children);
  const active = activeRoute(prop.path, level, hasChildren);
  const innerNavLinkStyles = {
    ...styles.collapseItemLink,
    ...(active && styles.black),
  };
  const navLinkStyles = {
    ...styles.itemLink,
    ...(active && styles.black),
  };

  const itemTextStyle = {
    ...styles.itemText,
    ...(!sidebarOpen && styles.itemTextMini),
  };

  const collapseItemTextStyle = {
    ...styles.collapseItemText,
    ...(!sidebarOpen && styles.collapseItemTextMini),
  };

  const listItemTextStyles = {
    ...(!!prop.icon && itemTextStyle),
    ...(prop.icon === undefined && collapseItemTextStyle),
    ...(active === 'active' && styles.collapseItemTextActive),
  };

  const caret = { ...styles.caret, ...styles[`caret-level-${level}`] };

  const [hover, setHover] = React.useState(isCollapseActive);

  return (
    <ListItem
      sx={{
        ...(!!prop.icon && styles.collapseItem),
        ...(!!prop.icon && styles.item),
        ...styles.listItem,
      }}
    >
      {hasCaret ? (
        <Box
          role="button"
          tabIndex={0}
          sx={{
            ...(!!prop.icon && navLinkStyles),
            ...(!prop.icon && innerNavLinkStyles),
          }}
          onClick={(e) => onNavLinkClick && onNavLinkClick(e)}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <SidebarItemIcon prop={prop} active={active} hover={hover} />
          <ListItemText
            primary={prop.name}
            secondary={
              hasCaret && (
                <Box
                  component="b"
                  sx={{
                    ...caret,
                    ...(isCollapseActive && styles.caretActive),
                  }}
                />
              )
            }
            disableTypography
            sx={listItemTextStyles}
          />
        </Box>
      ) : (
        <>
          {prop.external ? (
            <Box
              component="a"
              href={prop.path}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                ...(!!prop.icon && navLinkStyles),
                ...(!prop.icon && innerNavLinkStyles),
              }}
              onClick={(e) => onNavLinkClick && onNavLinkClick(e)}
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
            >
              <SidebarItemIcon prop={prop} active={active} hover={hover} />
              <ListItemText
                primary={prop.name}
                secondary={
                  hasCaret && (
                    <Box
                      component="b"
                      sx={{
                        ...caret,
                        ...(isCollapseActive && styles.caretActive),
                      }}
                    />
                  )
                }
                disableTypography
                sx={listItemTextStyles}
              />
            </Box>
          ) : (
            <Box
              sx={{
                margin: 0,
                padding: 0,
                '& a': {
                  ...(!!prop.icon && navLinkStyles),
                  ...(!prop.icon && innerNavLinkStyles),
                },
              }}
            >
              <CustomNavLink
                to={`${basePath}${prop.path}${keepParams(prop.paramsToKeep, location.search)}`}
                onClick={(e) => onNavLinkClick && onNavLinkClick(e)}
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
              >
                <SidebarItemIcon prop={prop} active={active} hover={hover} />
                <ListItemText
                  primary={prop.name}
                  secondary={
                    hasCaret && (
                      <Box
                        component="b"
                        sx={{
                          ...caret,
                          ...(isCollapseActive && styles.caretActive),
                        }}
                      />
                    )
                  }
                  disableTypography
                  sx={listItemTextStyles}
                />
              </CustomNavLink>
            </Box>
          )}
        </>
      )}
      {children}
    </ListItem>
  );
}

Link.propTypes = {
  children: PropTypes.node,
  hasCaret: PropTypes.bool,
  isCollapseActive: PropTypes.bool,
  prop: PropTypes.instanceOf(Object),
  sidebarOpen: PropTypes.bool,
  onNavLinkClick: PropTypes.func.isRequired,
  level: PropTypes.number,
  basePath: PropTypes.string,
};
Link.defaultProps = {
  children: null,
  hasCaret: false,
  isCollapseActive: false,
  prop: null,
  sidebarOpen: false,
  level: 0,
  basePath: '',
};

export default Link;
