import React, { useState } from 'react';
import { Star, StarOutline, ArrowForwardIos as ArrowIcon, Add } from '@mui/icons-material';
import { Checkbox, Box, Button, Collapse, List, ListItem, ListItemText } from '@mui/material';
import useLoginSeenCookie from '~/hooks/useLoginSeenCookie';
import { To, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserProfileQuery } from '~/views/Dashboard/UserProfile/userProfileQuery';
import CustomLink from '~/components/Commons/Link/Link';
import { getClientLogo } from './helpers';
import { Clients, Partners, SetDefaultAccountFunction } from '../ClientsSelector/types';
import { useSetDefaultAccountMutation } from '../ClientsSelector/helpers';
import CheckboxSkeleton from './CheckboxSkeleton';

type ClientTabProps = {
  client: Clients | Partners;
  onDoneClick: () => void;
  isNestedChild?: boolean;
};

function ClientTab({ client, onDoneClick, isNestedChild = false }: ClientTabProps) {
  // * State
  const [isChildClientMenuOpen, setIsChildClientMenuOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState('');
  const { data: userProfileData } = useUserProfileQuery();
  const navigate = useNavigate();

  // * Hooks
  const location = useLocation();
  const { setLoginSeenCookie } = useLoginSeenCookie();
  const { accountType, accountId } = useParams();

  const mutation = useSetDefaultAccountMutation();

  const handleSetDefaultAccount: SetDefaultAccountFunction = (clientId, isPartner, isChecked) => {
    mutation.mutate({ clientId, isPartner, isChecked });
  };

  // * Handlers
  const handleCreateChildClick =
    (destination: To) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setLoginSeenCookie();
      onDoneClick();
      navigate(destination);
    };

  const handleClick = () => {
    setLoginSeenCookie();
    onDoneClick();
  };

  const handleSetChildClientMenu = (clientId: string) => {
    if (isChildClientMenuOpen) {
      setIsChildClientMenuOpen(false);
      setSelectedClientId('');
    } else {
      setIsChildClientMenuOpen(true);
      setSelectedClientId(clientId);
    }
  };

  const clientLogo = getClientLogo(client.logo);

  // * Variables
  const isPartner = accountType === 'partner';
  const linkTo =
    accountId === client.id && isPartner === client.isPartner
      ? location
      : `/${client.isPartner ? 'partner' : 'client'}/${client.id}/dashboard`;

  let checked;
  if (userProfileData) {
    checked =
      !!client.id &&
      !!userProfileData.default_account.id &&
      client.id.toString() === userProfileData.default_account.id.toString() &&
      userProfileData.default_account.type !== 'none';
  }

  const isClient = 'allow_create_client' in client;

  const showChildrenMenuButton =
    !isNestedChild &&
    ((client.children && client.children.length > 0) || (isClient && client.allow_create_client));
  const showCreateClientButton = isClient ? client.allow_create_client : false;

  return (
    <List
      disablePadding
      component="nav"
      aria-labelledby="client-partner-list"
      sx={{
        width: '100%',
        height: 'fit-content',
        bgcolor: isChildClientMenuOpen ? '#F3F3F3' : null,
        borderBottom: '1px solid #ececec',
      }}
    >
      <ListItem
        disablePadding
        sx={{
          margin: '5px',
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {userProfileData ? (
            <Checkbox
              icon={<StarOutline data-testid="defaultAccountStar" />}
              checkedIcon={
                <Star data-testid="defaultAccountStarChecked" sx={{ color: 'warning.light' }} />
              }
              onChange={(e) =>
                handleSetDefaultAccount(client.id, client.isPartner, e.target.checked)
              }
              checked={checked}
              inputProps={{
                'aria-label': `${client.name} Checkbox to select default`,
              }}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.07) !important',
                },
              }}
            />
          ) : (
            <CheckboxSkeleton
              sx={{ height: 26, width: 26, marginLeft: '5px', marginRight: '7px' }}
            />
          )}
        </Box>
        <CustomLink
          style={{
            display: 'flex',
            alignItems: 'center',
            maxHeight: '4em',
            color: '#181919',
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            overflow: 'hidden',
          }}
          to={linkTo}
          onClick={handleClick}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              padding: '6px',
              marginRight: showChildrenMenuButton ? '0px' : '8px',
              flexGrow: 1,
              '&:hover': {
                backgroundColor: '#F3F3F3',
                color: 'blue',
              },
            }}
          >
            <img
              src={clientLogo}
              style={{
                width: '35px',
                height: '35px',
                padding: '6px',
                borderRadius: '50%',
                marginRight: '10px',
                textIndent: '-9999px',
              }}
              alt={client.logo ? client.name : `${client.name} logo placeholder`}
            />
            <ListItemText
              primary={client.name}
              primaryTypographyProps={{
                fontWeight: '600',
                sx: {
                  fontSize: {
                    xs: '0.7rem',
                    sm: '0.6rem',
                    md: '0.7rem',
                  },
                  wordBreak: 'break-word',
                },
              }}
            />
          </Box>
        </CustomLink>
        {!!showChildrenMenuButton && (
          <Button
            sx={{
              minHeight: '40px',
              cursor: 'pointer',
              minWidth: '30px',
              marginRight: '10px',
            }}
            onClick={
              showChildrenMenuButton ? () => handleSetChildClientMenu(client.id) : () => null
            }
            role="button"
          >
            <ArrowIcon
              sx={{
                transform: isChildClientMenuOpen ? 'rotate(90deg)' : 'rotate(0)',
                transition: 'transform 0.2s ease',
                display: showChildrenMenuButton ? 'block' : 'none',
              }}
              fontSize="small"
              data-testid="arrow-icon"
            />
          </Button>
        )}
      </ListItem>
      <Collapse in={isChildClientMenuOpen} timeout={800} unmountOnExit>
        <Box
          sx={{
            maxWidth: '100%',
            backgroundColor: 'whitesmoke',
            borderLeft: '2px solid black',
          }}
        >
          {showCreateClientButton && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                sx={{ margin: '10px 0px', textTransform: 'capitalize' }}
                onClick={handleCreateChildClick(`/client/${selectedClientId}/new-client`)}
              >
                <Add sx={{ mr: 1 }} />
                Create Client
              </Button>
            </Box>
          )}
          {client.children?.map((childClient) => (
            <ClientTab
              key={childClient.id}
              client={childClient}
              onDoneClick={onDoneClick}
              isNestedChild
            />
          ))}
        </Box>
      </Collapse>
    </List>
  );
}

export default ClientTab;
