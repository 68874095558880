import React from 'react';
import { Box, BoxProps } from '@mui/material';
import styles from './styles';

interface DashboardContainerProps extends BoxProps {
  children: React.ReactNode;
}

function DashboardContainer({ children, ...props }: DashboardContainerProps) {
  return <Box sx={{ ...styles.container, ...props.sx }}>{children}</Box>;
}

export default DashboardContainer;
