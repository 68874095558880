/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import defaultAvatar from '~/assets/img/placeholder.jpg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSession from '~/hooks/useSession/useSession';

import styles from './styles';
import SessionTimer from './SessionTimer';
import CustomLink from '../Link/Link';
import useSessionPlQuery from '../../../hooks/queries/useSessionPlQuery';

const StyledCustomDropdownLink = styled(CustomLink)({
  ...styles.link,
  ...styles.dropdownLink,
});

const StyledCustomMenuLink = styled(CustomLink)({
  ...styles.link,
  ...styles.menuLink,
});

function UserDropdownMenu() {
  const { logout } = useSession();
  const [openAvatar, setOpenAvatar] = useState(false);
  const { data: sessionPlData } = useSessionPlQuery();
  const toggleRef = useRef();
  const location = useLocation();
  const { accountType, accountId } = useParams();

  const onLogoutClick = () => {
    setOpenAvatar(false);
    logout(500);
  };

  const myDetailsContent = (
    <StyledCustomDropdownLink
      to={{ ...location, pathname: `/user-profile` }}
      title="My Details"
      onClick={() => setOpenAvatar(false)}
    >
      My Details
    </StyledCustomDropdownLink>
  );
  const logoutContent = (
    <StyledCustomDropdownLink to={location} title="Logout" onClick={onLogoutClick}>
      Logout
    </StyledCustomDropdownLink>
  );

  const staffContent = (
    <StyledCustomDropdownLink
      to={{ ...location, pathname: `/staff` }}
      title="Staff"
      onClick={() => setOpenAvatar(false)}
    >
      Staff
    </StyledCustomDropdownLink>
  );

  return (
    <ClickAwayListener onClickAway={() => setOpenAvatar(false)}>
      <Box sx={styles.container}>
        <SessionTimer />
        <Box
          sx={styles.photo}
          onClick={() => setOpenAvatar(!openAvatar)}
          role="menuitem"
          tabIndex={0}
        >
          <img
            src={sessionPlData?.login.logo ?? defaultAvatar}
            style={styles.avatarImg}
            alt={sessionPlData?.login.name ?? 'User profile'}
          />
        </Box>
        <Box sx={{ display: { md: 'none', lg: 'block' } }}>
          <List>
            <ListItem sx={styles.userItem}>
              <StyledCustomMenuLink
                to={`/${accountType}/${accountId}/user-profile`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenAvatar(!openAvatar);
                }}
                ref={toggleRef}
              >
                <ListItemText
                  primary={sessionPlData?.login.name ?? 'User profile'}
                  disableTypography
                  sx={styles.userLabel}
                />
                <ExpandMoreIcon sx={[styles.rightIcon, openAvatar && styles.rightIcon['&.open']]} />
              </StyledCustomMenuLink>
            </ListItem>
          </List>
        </Box>
        <Collapse in={openAvatar} unmountOnExit sx={styles.collapse}>
          <List>
            {!!sessionPlData?.login?.is_dianomi && !location.pathname.startsWith('/staff') && (
              <ListItem>
                <ListItemText primary={staffContent} disableTypography />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary={myDetailsContent} disableTypography />
            </ListItem>
            <ListItem>
              <ListItemText primary={logoutContent} disableTypography />
            </ListItem>
          </List>
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
}

UserDropdownMenu.propTypes = {};
UserDropdownMenu.defaultProps = {};

export default UserDropdownMenu;
