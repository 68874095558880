import React, { useState } from 'react';
import { Info } from '@mui/icons-material';
import { ClickAwayListener, Button, Tooltip } from '@mui/material';

type DialogInfoTooltipProps = {
  title?: string;
  children: React.ReactNode;
  isValid?: boolean;
  forceZIndex?: number;
  iconTestId?: string;
};

function DialogInfoTooltip({
  title = 'Information',
  children = null,
  isValid = undefined,
  forceZIndex = undefined,
  iconTestId = '',
}: DialogInfoTooltipProps) {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          title={children}
          arrow
          open={open}
          placement="bottom-start"
          slotProps={{
            tooltip: {
              sx: (theme) => ({
                backgroundColor: theme.palette.info.main,
                padding: 1.5,
                '& .MuiTooltip-arrow': {
                  color: theme.palette.info.main,
                },
                fontSize: '0.85em',
              }),
            },
            ...(forceZIndex != null && {
              popper: {
                sx: {
                  zIndex: forceZIndex,
                },
              },
            }),
          }}
        >
          <Button
            onClick={() => setOpen(!open)}
            color="secondary"
            sx={(theme) => ({
              backgroundColor: theme.palette.info.main,
              '&:hover': {
                backgroundColor: theme.palette.info.main,
              },
            })}
            data-testid={iconTestId}
            aria-invalid={isValid}
          >
            {title}
            <Info sx={{ color: 'white', fontSize: '16px', marginLeft: '3px' }} />
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default DialogInfoTooltip;
