import React from 'react';
import propTypes from 'prop-types';
import { desktopBreakpoint } from '~/variables/breakpoints';

class Responsive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { children, desktop, mobile } = this.props;
    const { width } = this.state;

    if ((width >= desktopBreakpoint && desktop) || (width < desktopBreakpoint && mobile)) {
      return children;
    }

    return null;
  }
}

Responsive.propTypes = {
  children: propTypes.node,
  mobile: propTypes.bool,
  desktop: propTypes.bool,
};
Responsive.defaultProps = {
  children: null,
  mobile: null,
  desktop: null,
};

export default Responsive;
