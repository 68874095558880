import React from 'react';
import { Box } from '@mui/material';

const styles = {
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  root: {
    opacity: 1,
    animationName: 'fadeIn',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '.25s',
  },
};

type FadeInProps = {
  children: React.ReactNode;
};

function FadeIn({ children }: FadeInProps) {
  return <Box sx={styles.root}>{children}</Box>;
}

export default FadeIn;
