import { useState, useEffect } from 'react';
import { desktopBreakpoint } from '~/variables/breakpoints';
import useDebounce from './useDebounce';

const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < desktopBreakpoint);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const debouncedIsMobile = useDebounce(isMobile, 200);
  const debouncedInnerHeight = useDebounce(innerHeight, 200);
  const debouncedInnerWidth = useDebounce(innerWidth, 200);
  useEffect(() => {
    const setWindowParams = () => {
      const newInnerHeight = window.innerHeight;
      const newInnerWidth = window.innerWidth;
      const newIsMobile = innerWidth < desktopBreakpoint;
      if (
        newIsMobile !== isMobile ||
        newInnerHeight !== innerHeight ||
        newInnerWidth !== innerWidth
      ) {
        setIsMobile(newIsMobile);
        setInnerHeight(newInnerHeight);
        setInnerWidth(newInnerWidth);
      }
    };
    window.addEventListener('resize', setWindowParams);
    setWindowParams();
    return () => window.removeEventListener('resize', setWindowParams);
  }, [innerHeight, innerWidth, isMobile]);
  return {
    isMobile: debouncedIsMobile,
    innerHeight: debouncedInnerHeight,
    innerWidth: debouncedInnerWidth,
  };
};

export default useWindowSize;
