import { Theme } from '@mui/material';

const styles = {
  button: {
    minWidth: '20px',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
    padding: '10px',
    whiteSpace: 'nowrap',
    marginRight: '10px',
    '&[disabled]': {
      color: (theme: Theme) => theme.palette?.grey[900],
    },
  },
  primary: {
    background: (theme: Theme) => theme.palette?.primary.main,
    color: (theme: Theme) => theme.palette?.secondary.main,
    '&:hover': {
      background: (theme: Theme) => theme.palette?.info.light,
      color: (theme: Theme) => theme.palette?.secondary.main,
    },
  },
  small: {
    padding: '8px 16px',
    margin: '0',
  },
  xsmall: {
    padding: '4px 12px',
  },
  secondary: {
    background: (theme: Theme) => theme.palette?.secondary.main,
    color: (theme: Theme) => theme.palette?.primary.main,
    '&:hover': {
      background: (theme: Theme) => theme.palette?.info.light,
      color: (theme: Theme) => theme.palette?.secondary.main,
    },
  },
  error: {
    background: (theme: Theme) => theme.palette?.error.main,
    color: (theme: Theme) => theme.palette?.primary.main,
    '&:hover': {
      background: (theme: Theme) => theme.palette?.info.light,
      color: (theme: Theme) => theme.palette?.secondary.main,
    },
  },
  bordered: {
    background: 'transparent',
    color: (theme: Theme) => theme.palette?.primary.main,
    border: (theme: Theme) => `1px solid${theme.palette?.grey[300]}`,
    '&:hover': {
      background: (theme: Theme) => theme.palette?.info.light,
      color: (theme: Theme) => theme.palette?.secondary.main,
    },
  },
  wrap: {
    whiteSpace: 'normal',
  },
  isValid: {
    border: '2px solid',
    borderColor: (theme: Theme) => theme.palette?.success.main,
  },
} as const;

export default styles;
