import React from 'react';
import { useLocation } from 'react-router-dom';
import ActionConfirmDialog from '.';
import dialog, { useDialog } from './useDialog';

function GlobalDialog() {
  const location = useLocation();
  const {
    isOpen,
    showCloseButton,
    onAccept,
    onClose,
    body,
    acceptText,
    cancelText,
    title,
    width,
    isSubmitting,
    fullWidth,
    fullHeight,
  } = useDialog();

  React.useEffect(() => {
    dialog.close();
  }, [location.pathname]);
  return (
    <ActionConfirmDialog
      open={isOpen}
      showCloseButton={showCloseButton}
      handleDialogConfirm={onAccept}
      handleDialogClose={onClose || (() => dialog.close())}
      message={body}
      acceptText={acceptText}
      cancelText={cancelText}
      title={title}
      actions={!!onAccept || !!onClose}
      maxWidth={width}
      isSubmitting={isSubmitting}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
    />
  );
}

export default GlobalDialog;
