import React from 'react';
import { Box, capitalize } from '@mui/material';
import HTMLReactParser from 'html-react-parser';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import { numberFieldsObj, isCurrencyField, minFractionDigitsMap } from './utils';

const styles = {
  creative: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '300px',
    '@media screen and (max-width: 1560px)': {
      flexWrap: 'wrap',
      minWidth: '200px',
    },
  },
  creativeText: {
    width: '220px',
  },
};

const availableCurrencies: Record<string, string> = {
  '&pound;': '£',
  $: '$',
  AU$: 'AU$',
  CA$: 'CA$',
  S$: 'S$',
  Rs: 'Rs',
  '&euro': '€',
  HK$: 'HK$',
  CHF: 'CHF',
  MYR: 'MYR',
  ZAR: 'ZAR',
  INR: 'INR',
  US$: 'US$',
  SGD: 'SGD',
  A$: 'A$',
};

// Component to parse the "creative" HTML and add some styles to help the
// content fit the design.
function Creative({ html }: { html: string }) {
  if (typeof html !== 'string') {
    return html;
  }
  return <Box sx={styles.creative}>{HTMLReactParser(html)}</Box>;
}

export const isNumber = (value?: string) => (value ? numberFieldsObj[value.toLowerCase()] : false);

type TableCellProps = {
  column: {
    accessor?: string;
    header: string;
  };
  value: string | number | null | React.ReactNode;
  currency?: string;
  formatNumbers?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: Record<string, any>;
};

function TableCell({
  column,
  value,
  currency = undefined,
  formatNumbers = false,
  row = {},
}: TableCellProps) {
  try {
    let { accessor, header } = column;

    let newValue = value;
    /**
     * Columns with "Custom." are normally creative or partner or image columns
     * with html in them that we have parsed to get the useful data out.
     * the format is normally `${Partner | Creative | Image}Custom.normalizedValue`
     * and the 'normalizedValue' is what we would use for searching/filtering
     * see Analytics/utils.tsx for the implementation of this.
     */
    if (accessor?.includes('Custom.')) {
      // topKey in this case will be `${Partner | Creative | Image}Custom`
      const [topKey] = accessor.split('.');
      const val = row[topKey];

      if (val && typeof val === 'object') {
        const imgUrl =
          process.env.REACT_APP_ENVIRONMENT !== 'prod' && val.imgSrc
            ? val.imgSrc.replace('/img', '/preview/img')
            : val.imgSrc;
        return (
          <div>
            {imgUrl && (
              <img
                src={imgUrl}
                alt={val.imgAlt ?? val.imgTitle ?? ''}
                title={val.imgTitle ?? val.imgAlt ?? ''}
              />
            )}
            {val.innerText ? <div>{val.innerText}</div> : null}
          </div>
        );
      }
      // if we get here, it means the parsing didn't work correctly,
      // so we'll get the original value before we tried to parse it,
      // and do as normal below
      [accessor] = accessor.split('Custom.');
      header = capitalize(accessor);
      newValue = row[accessor];
    }

    if (newValue == null) {
      return null;
    }

    if (formatNumbers) {
      if (isNumber(accessor) && !Number.isNaN(Number(newValue))) {
        const prettyValue = Number(newValue).toLocaleString(navigator.language, {
          maximumFractionDigits: 2,
          minimumFractionDigits: accessor ? minFractionDigitsMap[accessor] : 0,
        });

        return <>{prettyValue}</>;
      }
      if (isCurrencyField(accessor)) {
        const currencyValue = `${currency}${Number(newValue).toLocaleString(navigator.language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
        return <>{currencyValue}</>;
      }
    }
    newValue =
      process.env.REACT_APP_ENVIRONMENT !== 'prod' && typeof newValue === 'string'
        ? newValue.replace('/img', '/preview/img')
        : newValue;
    switch (header?.toLowerCase()) {
      case 'currency':
        // we know the value is string when header is 'currency
        return <>{availableCurrencies[newValue as string] || null}</>;

      case 'creative':
        return <Creative html={newValue as string} />;

      case 'image':
      case 'partner':
      case 'publisher':
      case 'img':
        return <div style={{ textAlign: 'center' }}>{HTMLReactParser(newValue as string)}</div>;

      case 'date': {
        // do our best with dates and if they fail, return original value
        const mDate = dayjs(newValue as string);
        if (mDate.isValid()) {
          if (accessor === 'yyyymm') {
            return <>{mDate.format('MMMM YYYY')}</>;
          }
          const formatType = navigator.language === 'en-US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
          return <>{mDate.format(formatType)}</>;
        }
        return <>{newValue}</>;
      }

      default:
        return <>{newValue}</>;
    }
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to Format cell data()',
        file: 'TableCell.js',
        fn: 'export default()-01',
      });
      Sentry.captureException(e);
    });
    return <>N/A</>;
  }
}

export default TableCell;
