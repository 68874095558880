import * as React from 'react';
import { useCookies } from 'react-cookie';

export default function useLoginSeenCookie() {
  const [cookie, setCookie, removeCookies] = useCookies(['loginSeen']);

  const setLoginSeenCookie = React.useCallback(() => {
    setCookie('loginSeen', 'true', { path: '/' });
  }, [setCookie]);

  const removeLoginSeenCookie = React.useCallback(() => {
    removeCookies('loginSeen');
  }, [removeCookies]);

  return {
    loginSeen: cookie.loginSeen,
    setLoginSeenCookie,
    removeLoginSeenCookie,
  };
}
