import useCrossTabState from '~/hooks/useCrossTabState';
import useTimeoutToggler from '~/hooks/useTimeoutToggler';
import React, { useEffect, useCallback } from 'react';
import ValidationService from '~/services/ValidationService';
import useSession from '~/hooks/useSession/useSession';
import useNotifications from '~/context/Notifications';
import { Box } from '@mui/material';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';

function SessionTimer() {
  const [warningTimeout, resetWarningTimeout] = useTimeoutToggler(1000 * 60 * 45); // 45mins
  const [logoutTimeout, resetLogoutTimeout] = useTimeoutToggler(1000 * 60 * 59); // 59mins
  const [sessionPlTracker] = useCrossTabState('sessionPlTracker', 0);
  const sessionPlQuery = useSessionPlQuery((data) => ({
    accountId: data.id,
    accountType: data.accountType,
  }));
  const { setNotification, closeNotification } = useNotifications();
  const { logout } = useSession();

  const handleStayLoggedIn = useCallback(async () => {
    const sessionInfo = await ValidationService.checkClientSession(sessionPlQuery.data);
    if (!sessionInfo || sessionInfo.error) {
      logout();
    } else {
      closeNotification();
    }
  }, [sessionPlQuery.data, logout, closeNotification]);

  useEffect(() => {
    if (warningTimeout) {
      // 15 mins to go before logout
      const message = (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Box sx={{ whiteSpace: 'nowrap' }}>You seem to have been idle for a while,</Box>
          <Box
            component="a"
            role="button"
            tabIndex="0"
            onClick={handleStayLoggedIn}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                cursor: 'pointer',
                color: 'darkblue',
              },
            }}
          >
            {` Click here `}
          </Box>
          to stay logged in
        </Box>
      );
      setNotification(true, 'warning', message);
    }
  }, [warningTimeout, setNotification, handleStayLoggedIn]);

  useEffect(() => {
    if (logoutTimeout) {
      logout(0);
    }
  }, [logout, logoutTimeout]);

  useEffect(() => {
    // when sessionStorage changes, call reset timeouts
    let isMounted = true;
    if (isMounted) {
      resetLogoutTimeout();
      resetWarningTimeout();
    }
    return () => {
      isMounted = false;
    };
  }, [resetLogoutTimeout, resetWarningTimeout, sessionPlTracker]);

  return null;
}

export default SessionTimer;
