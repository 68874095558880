const styles = {
  suggestionsList: {
    position: 'absolute',
    backgroundColor: 'white',
    padding: '20px',
    margin: '0px',
    zIndex: '100',
    left: '0',
    right: '0',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  suggestion: {
    padding: '5px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ececec',
    cursor: 'pointer',
  },
};

export default styles;
