import React from 'react';
import Snack, { SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import snackbarContentStyle from '~/components/Commons/Snackbar/snackbarContentStyle';
import { NotificationLevels } from '~/context/Notifications';

interface SnackbarProps extends MuiSnackbarProps {
  message: React.ReactNode;
  color: NotificationLevels;
  close: boolean;
  place: 'tl' | 'tr' | 'tc' | 'br' | 'bl' | 'bc';
  open: boolean;
  closeNotification: () => void;
}

function Snackbar({ ...props }: SnackbarProps) {
  const { message, color, close, place, open } = props;
  let action: React.ReactNode = [];
  if (close !== undefined) {
    action = [
      <IconButton
        sx={snackbarContentStyle.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeNotification()}
        size="large"
      >
        <Close sx={snackbarContentStyle.close} />
      </IconButton>,
    ];
  }

  const verticalPosition = place.indexOf('t') === -1 ? 'bottom' : 'top';
  let horizontalPosition: 'right' | 'left' | 'center' = 'right';
  if (place.indexOf('l') !== -1) {
    horizontalPosition = 'left';
  } else if (place.indexOf('c') !== -1) {
    horizontalPosition = 'center';
  }

  return (
    <Snack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{
        '&.MuiSnackbar-anchorOriginTopCenter': snackbarContentStyle.top20,
        '&.MuiSnackbar-anchorOriginTopRight': snackbarContentStyle.top40,
        '&.MuiSnackbar-anchorOriginTopLeft': snackbarContentStyle.top40,
        '&.MuiSnackbarContent-root.MuiPaper-root': {
          ...snackbarContentStyle.root,
        },
        ...props.sx,
      }}
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
      open={open}
      message={message}
      action={action}
      ContentProps={{
        sx: {
          '&.MuiSnackbarContent-root.MuiPaper-root': {
            ...snackbarContentStyle.primary,
            ...snackbarContentStyle[color],
          },
          '& .MuiSnackbarContent-message': snackbarContentStyle.message,
        },
      }}
    />
  );
}

export default Snackbar;
