import React from 'react';
import PropTypes from 'prop-types';
import useNotifications from '~/context/Notifications';
import { useQueryClient } from '@tanstack/react-query';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

// material-ui icons
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';

// core components
import Button from '~/components/Commons/Button';

// Dianomi components
import { Box, Typography } from '@mui/material';
import adminNavbarStyle from '~/components/Commons/Navbars/adminNavbarStyle';
import logo from '~/assets/img/header_logo.png';
import GridContainer from '~/components/Commons/Grid/GridContainer';
import GridItem from '~/components/Commons/Grid/GridItem';
import Responsive from '~/components/Commons/Responsive';
import NavbarClientSelector from '~/components/Clients/NavbarClientSelector';
import AdminNavbarLinks from './AdminNavbarLinks';
import { PageLoadingIndicator } from '../PageLoadingIndicator/PageLoadingIndicator';
import CustomLink from '../Link/Link';
import { AccountSuspendedIndicator } from '../AccountSuspendedIndicator/AccountSuspendedIndicator';

function AdminNavbar({
  activeRoute,
  handleDrawerToggle,
  sidebarMinimize,
  mobileNavOpen,
  showAccountSwitcher,
  isAuthBar,
}) {
  const { setNotification, closeNotification } = useNotifications();
  const queryClient = useQueryClient();

  return (
    <Box
      sx={{
        '& header': {
          // Not sure why i need this but for some reason when opening any dropdown selector,
          // the header is getting 15px padding on the right side ???
          paddingRight: '0px !important',
        },
      }}
    >
      <Responsive mobile>
        <PageLoadingIndicator />
        <AppBar sx={adminNavbarStyle.appBarMobile}>
          <Toolbar sx={adminNavbarStyle.container}>
            {!isAuthBar ? (
              <GridContainer>
                <GridItem xs={1}>
                  <Box sx={{ display: { md: 'none', sm: 'block' } }}>
                    <Button
                      sx={adminNavbarStyle.appDrawerMobile}
                      aria-label="open drawer"
                      onClick={handleDrawerToggle}
                    >
                      {mobileNavOpen ? <Close /> : <Menu />}
                    </Button>
                  </Box>
                </GridItem>
                {!isAuthBar && (
                  <>
                    <GridItem xs={10}>
                      <Typography variant="h1" sx={adminNavbarStyle.appTitleMobile}>
                        {activeRoute}
                      </Typography>
                    </GridItem>
                    <GridItem xs={1}>
                      <AdminNavbarLinks
                        setNotification={setNotification}
                        closeNotification={closeNotification}
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>
            ) : (
              <Box sx={adminNavbarStyle.logo}>
                <CustomLink
                  to="/"
                  style={adminNavbarStyle.logoNormal}
                  onClick={() => queryClient.clear()}
                >
                  <img src={logo} alt="Dianomi" style={adminNavbarStyle.img} />
                </CustomLink>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <AccountSuspendedIndicator />
      </Responsive>
      <Responsive desktop>
        <PageLoadingIndicator />
        <AppBar sx={(theme) => adminNavbarStyle.appBar(theme)}>
          <Toolbar style={adminNavbarStyle.container}>
            {!isAuthBar ? (
              <>
                <Box sx={adminNavbarStyle.logo}>
                  <Button
                    sx={adminNavbarStyle.appDrawer}
                    aria-label="open drawer"
                    onClick={sidebarMinimize}
                  >
                    <Menu sx={{ fontSize: '1.1em' }} />
                  </Button>
                  <CustomLink
                    to="/"
                    style={adminNavbarStyle.logoNormal}
                    onClick={() => queryClient.clear()}
                  >
                    <img src={logo} alt="Dianomi" style={adminNavbarStyle.img} />
                  </CustomLink>
                </Box>
                {showAccountSwitcher ? <NavbarClientSelector /> : <div style={{ flex: 1 }} />}

                <Box sx={{ display: { sm: 'none', md: 'block' } }}>
                  <AdminNavbarLinks
                    setNotification={setNotification}
                    closeNotification={closeNotification}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box sx={adminNavbarStyle.logo}>
                  <Button
                    sx={adminNavbarStyle.appDrawer}
                    aria-label="open drawer"
                    onClick={() => null}
                  />
                  <CustomLink
                    to="/"
                    style={adminNavbarStyle.logoNormal}
                    onClick={() => queryClient.clear()}
                  >
                    <img src={logo} alt="Dianomi" style={adminNavbarStyle.img} />
                  </CustomLink>
                </Box>
                <div style={{ flex: 1 }} />
              </>
            )}
          </Toolbar>
        </AppBar>
        <AccountSuspendedIndicator />
      </Responsive>
    </Box>
  );
}

AdminNavbar.propTypes = {
  activeRoute: PropTypes.string,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  mobileNavOpen: PropTypes.bool,
  showAccountSwitcher: PropTypes.bool,
  isAuthBar: PropTypes.bool,
};

AdminNavbar.defaultProps = {
  mobileNavOpen: false,
  showAccountSwitcher: true,
  isAuthBar: false,
  activeRoute: null,
  handleDrawerToggle: null,
  sidebarMinimize: null,
};

export default AdminNavbar;
