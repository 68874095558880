import useWindowSize from '~/hooks/useWindowSize';
import * as React from 'react';
import { desktopBreakpoint } from '~/variables/breakpoints';

const MenuContext = React.createContext();

// eslint-disable-next-line react/prop-types
export const MenuProvider = ({ children }) => {
  const { innerWidth, isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = React.useState(!isMobile);

  const toggle = React.useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const closeMenu = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  React.useEffect(() => {
    // close menu whenever we change from !isMobile to isMobile
    if (isMobile) {
      closeMenu();
    } else {
      // otherwise open if we change from isMobile to !isMobile
      setIsOpen(true);
    }
  }, [isMobile, closeMenu]);

  const menuMinimized = !isOpen && innerWidth < desktopBreakpoint;
  return (
    <MenuContext.Provider value={{ isOpen, toggle, closeMenu, menuMinimized }}>
      {children}
    </MenuContext.Provider>
  );
};

const useMenu = () => {
  const context = React.useContext(MenuContext);

  if (!context) {
    throw new Error('useMiniMenu must be used in the MiniMenuProvider');
  }
  return context;
};

export default useMenu;
