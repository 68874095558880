import Box from '@mui/material/Box';
import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigation } from 'react-router-dom';

export function PageLoadingIndicator() {
  const navigation = useNavigation();
  return navigation.state !== 'idle' ? (
    <LinearProgress />
  ) : (
    <Box sx={{ height: '4px', backgroundColor: 'primary.main' }} />
  );
}
