/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';

const ActionType = {
  OPEN_DIALOG: 'OPEN_DIALOG',
  CLOSE_ACTION: 'CLOSE_ACTION',
  ACCEPT_ACTION: 'ACCEPT_ACTION',
  UPDATE_DIALOG: 'UPDATE_DIALOG',
  RESET_DIALOG: 'RESET_DIALOG',
  IS_SUBMITTING_ACTION: 'IS_SUBMITTING_ACTION',
} as const;

const initialState: State = {
  isOpen: false,
  cancelText: 'Cancel',
  acceptText: 'Okay',
  onOpen: null,
  onClose: null,
  onAccept: null,
  width: 'sm',
  title: '',
  showCloseButton: true,
  body: null,
  isSubmitting: false,
  fullWidth: false,
  fullHeight: false,
};

type State = {
  isOpen: boolean;
  cancelText: string;
  acceptText: string;
  onOpen: (() => void) | null;
  onClose: ((e?: React.MouseEvent<HTMLElement>) => void) | undefined | null;
  onAccept: ((e?: React.MouseEvent<HTMLElement>) => void) | undefined | null;
  width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  title: string;
  showCloseButton: boolean;
  body: React.ReactNode;
  isSubmitting: boolean;
  fullWidth: boolean;
  fullHeight: boolean;
};

type Action = { type: (typeof ActionType)[keyof typeof ActionType]; options?: Partial<State> };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.OPEN_DIALOG: {
      if (action.options?.onOpen) {
        action.options?.onOpen();
      }
      return {
        ...initialState,
        isOpen: true,
        ...action.options,
      };
    }
    case ActionType.CLOSE_ACTION: {
      if (action.options?.onClose) {
        action.options?.onClose();
      }
      return {
        ...state,
        isOpen: false,
        ...action.options,
      };
    }
    case ActionType.ACCEPT_ACTION: {
      if (action.options?.onAccept) {
        action.options?.onAccept();
      }
      return {
        ...state,
        ...action.options,
      };
    }
    case ActionType.UPDATE_DIALOG: {
      return {
        ...state,
        ...action.options,
      };
    }
    case ActionType.IS_SUBMITTING_ACTION: {
      return {
        ...state,
        isSubmitting: true,
        ...action.options,
      };
    }
    case ActionType.RESET_DIALOG: {
      return {
        ...initialState,
        ...action.options,
      };
    }
    default:
      return state;
  }
};

let listener: React.Dispatch<State> | null = null;

let memoryState = {
  ...initialState,
};

const dispatch = (action: Action) => {
  memoryState = reducer(memoryState, action);
  if (listener) {
    listener(memoryState);
  }
  return memoryState;
};

export const useDialog = (dialogOptions = {}) => {
  const [state, setState] = useState(memoryState);
  useEffect(() => {
    listener = setState;
  }, [state]);
  return {
    ...state,
    ...dialogOptions,
  };
};

const createHandler = (actionType: Action['type']) => (options?: Partial<State>) =>
  dispatch({ type: actionType, options });

const dialog = (options: Partial<State>) => createHandler(ActionType.OPEN_DIALOG)(options);

dialog.open = createHandler(ActionType.OPEN_DIALOG);
dialog.update = createHandler(ActionType.UPDATE_DIALOG);
dialog.submitting = createHandler(ActionType.IS_SUBMITTING_ACTION);
dialog.accept = createHandler(ActionType.ACCEPT_ACTION);
dialog.close = createHandler(ActionType.CLOSE_ACTION);
dialog.reset = createHandler(ActionType.RESET_DIALOG);

dialog.promise = async (
  promise: Promise<unknown>,
  options: { submitting: Partial<State>; resolve: Partial<State>; reject: Partial<State> },
) => {
  dialog.submitting(options?.submitting);
  promise
    .then((result) => {
      if (result) {
        dialog.close(options?.resolve);
      }
      dialog.update({ isSubmitting: false });
      return result;
    })
    .catch((error) => {
      dialog.update({ isSubmitting: false, ...options?.reject });
      return error;
    });
  return promise;
};

export default dialog;
