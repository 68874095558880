import React from 'react';
import { DialogActions } from '@mui/material';

import Button from '~/components/Commons/Button';

import LoadingProgress from '../LoadingProgress';

function DialogButtons({
  isSubmitting,
  handleDialogConfirm,
  acceptText = 'Save',
  showCloseButton,
  handleDialogClose,
  cancelText,
}: {
  isSubmitting?: boolean;
  handleDialogConfirm?: ((e: React.MouseEvent<HTMLElement>) => void) | null | undefined;
  acceptText?: string;
  showCloseButton?: boolean;
  handleDialogClose?: (e: React.MouseEvent<HTMLElement>) => void;
  cancelText?: string;
}) {
  return (
    <DialogActions sx={{ justifyContent: 'center', margin: '20px' }}>
      {isSubmitting ? (
        <LoadingProgress loading={isSubmitting} />
      ) : (
        <>
          {handleDialogConfirm && (
            <Button onClick={handleDialogConfirm} color="primary" name={acceptText}>
              {acceptText}
            </Button>
          )}
          {showCloseButton && (
            <Button
              onClick={handleDialogClose}
              color="primary"
              name={handleDialogConfirm ? 'Cancel' : 'Close'}
            >
              {cancelText || (handleDialogConfirm ? 'Cancel' : 'Close')}
            </Button>
          )}
        </>
      )}
    </DialogActions>
  );
}

export default DialogButtons;
