import React, { useState } from 'react';
import useWindowSize from '~/hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '~/components/Commons/Button';
import SectionTitle from '~/components/Typography/SectionTitle';
import Tabs from '~/components/Commons/CustomTabs/Tabs';
import Tab from '~/components/Commons/CustomTabs/Tab';
import FadeIn from '~/components/Commons/FadeIn';
import AutoSuggest from '~/components/Commons/AutoSuggest/';
import useNotifications from '~/context/Notifications';
import {
  ClientsSelectorPropTypes,
  Clients,
  Partners,
  DesktopTabletClientsSelectorTypes,
  MobileClientsSelectorTypes,
} from './types';
import ClientTab from '../ClientTab/ClientTab';
import MenuSkeleton from './MenuSkeleton';

function ClientsSelector({ accounts, onDoneClick, isOnLogin }: ClientsSelectorPropTypes) {
  // * Hooks
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { setNotification } = useNotifications();
  // * Ref
  const autoSuggestRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (autoSuggestRef.current) {
      autoSuggestRef.current.focus();
    }
  }, []);

  // * Handlers
  const handleSearchBarClientClick = (accountId: number) => {
    const selectedClient = accounts?.flatAccounts.find(
      (clientData) => clientData.id.toString() === accountId.toString(),
    );
    if (!selectedClient) {
      setNotification(
        true,
        'danger',
        'Permission denied: you cannot access the selected account. Please contact your Account Manager if this is unexpected behaviour',
      );
    }
    if (selectedClient) {
      const { isPartner } = selectedClient;
      navigate(`/${isPartner ? 'partner' : 'client'}/${accountId}/dashboard`);
      if (onDoneClick !== undefined) {
        onDoneClick();
      }
    }
  };

  // autoSuggest returns multiple params (which productType component needs) which is messing with handleClientClick, this func passes required params to handleClientClick
  const handleAutoSuggestClientClick = (accountId: number) => {
    handleSearchBarClientClick(accountId);
  };

  let autosuggestAccounts: (Partners | Clients)[] = [];
  let placeholder = 'Search for ';
  let divider;
  const sections: { enabled: boolean; title: string; tabs: Clients[] | Partners[] }[] = [];

  if (accounts) {
    sections.push({
      enabled: accounts.clientsCount > 0,
      title: 'Advertisers',
      tabs: accounts.clients,
    });

    sections.push({
      enabled: accounts.partnersCount > 0,
      title: 'Publishers',
      tabs: accounts.partners,
    });

    autosuggestAccounts = [...accounts.partners, ...accounts.clients];

    if (accounts.clientsCount > 0 && accounts.partnersCount > 0) {
      placeholder += 'Publishers/Advertisers';
      divider = true;
    } else if (accounts.partnersCount > 0) {
      placeholder += 'Publishers';
      divider = false;
    } else {
      placeholder += 'Advertisers';
      divider = false;
    }
  }

  return (
    <>
      {/* Search Bar */}
      {accounts && accounts.flatAccounts?.length > 4 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: !isOnLogin ? '10px' : '0px',
          }}
        >
          <Paper
            sx={{
              width: '90%',
              position: 'relative',
              boxShadow: 'none',
              marginBottom: isMobile ? '10px' : '0px',
              '& div input': {
                width: 'inherit',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid black',
                borderRadius: '4px',
              },
            }}
          >
            <AutoSuggest
              ref={autoSuggestRef}
              items={autosuggestAccounts}
              onSelectedItem={handleAutoSuggestClientClick}
              placeholder={placeholder}
              includeParent
            />
          </Paper>
        </Box>
      )}
      {accounts ? (
        <FadeIn>
          {isMobile ? (
            <>
              {/*  eslint-disable-next-line no-use-before-define */}
              <MobileClientsSelector
                accounts={accounts}
                sections={sections}
                isOnLogin={isOnLogin}
                onDoneClick={onDoneClick}
              />
            </>
          ) : (
            // eslint-disable-next-line no-use-before-define
            <DesktopTabletClientsSelector
              sections={sections}
              divider={divider}
              onDoneClick={onDoneClick}
            />
          )}
        </FadeIn>
      ) : (
        <MenuSkeleton />
      )}
    </>
  );
}

export default ClientsSelector;

function DesktopTabletClientsSelector({
  sections,
  divider,
  onDoneClick,
}: DesktopTabletClientsSelectorTypes) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flex: '1',
        height: '100%',
      }}
    >
      {sections.map(
        (section, index) =>
          section.enabled && (
            <React.Fragment key={section.title}>
              {/* Container for Section Title and Tabs container */}
              <Box
                sx={(theme) => ({
                  flex: '1',
                  [theme.breakpoints.up('sm')]: {
                    marginTop: '10px',
                    marginRight: '2px',
                    marginLeft: '2px',
                  },
                  [theme.breakpoints.up('md')]: {
                    margin: '20px',
                  },
                })}
              >
                <SectionTitle
                  style={{
                    padding: '15px',
                    margin: '0',
                    borderBottom: '1px solid #ececec',
                    fontSize: '1.2em',
                    textAlign: 'center',
                  }}
                >
                  {section.title}
                </SectionTitle>
                {/* Container for all tabs */}
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    maxHeight: '300px',
                    ...theme.defaultStyles.scrollBar,
                  })}
                >
                  {section.tabs.map((tab) => (
                    <ClientTab key={tab.id} client={tab} onDoneClick={onDoneClick} />
                  ))}
                </Box>
              </Box>
              {index === 0 && divider && (
                <Divider
                  key="divider"
                  flexItem
                  orientation="vertical"
                  sx={(theme) => ({
                    [theme.breakpoints.up('md')]: {
                      margin: '15px 15px 0',
                    },
                  })}
                />
              )}
            </React.Fragment>
          ),
      )}
    </Box>
  );
}

function MobileClientsSelector({
  sections,
  accounts,
  isOnLogin,
  onDoneClick,
}: MobileClientsSelectorTypes) {
  // * State
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };
  return (
    <>
      {accounts && (
        <>
          <Paper sx={{ boxShadow: 'none' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                diplay: 'flex',
                margin: '1px',
                width: '100%',
                marginRight: '2px',
              }}
              variant="fullWidth"
            >
              {accounts?.clientsCount > 0 && <Tab label="Advertisers" style={{ flexGrow: '1' }} />}
              {accounts?.partnersCount > 0 && <Tab label="Publishers" style={{ flexGrow: '1' }} />}
            </Tabs>
          </Paper>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              flexShrink: 0,
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: '380px',
              ...theme.defaultStyles.scrollBar,
            })}
          >
            {sections[tabValue].tabs.map((tab) => {
              return <ClientTab key={tab.id} client={tab} onDoneClick={onDoneClick} />;
            })}
          </Box>
          {!isOnLogin && (
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#000000',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: '#ffffff',
                  textTransform: 'none',
                  minWidth: '130px',
                  margin: '0px 2px 6px 2px',
                }}
                onClick={onDoneClick}
              >
                Done
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
}
