import React from 'react';
import TabBase, { TabProps as MuiTabsProps } from '@mui/material/Tab';
import { Theme } from '@mui/material/styles';

const styles = {
  root: {
    textTransform: 'initial',
    minWidth: 0,
    minHeight: 0,
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px 16px',
    color: (theme: Theme) => theme.palette?.text.primary,
    backgroundColor: (theme: Theme) => theme.palette?.secondary.main,
    flexGrow: 1,
    '&:hover': {
      color: (theme: Theme) => theme.palette?.primary.light,
      fontWeight: 'bold',
      opacity: 1,
    },
    '&.tabSelected': {
      color: (theme: Theme) => theme.palette?.secondary.main,
      fontWeight: 400,
    },
    '&:focus': {
      color: (theme: Theme) => theme.palette?.primary.light,
    },
  },
  selected: {
    '&.Mui-selected': {
      fontWeight: 'bold !important',
    },
  },
} as const;

interface TabProps extends MuiTabsProps {
  label: string;
}

function Tab({ label, ...props }: TabProps) {
  return (
    <TabBase
      sx={{ ...styles.root, ...styles.selected }}
      label={label}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default Tab;
