import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

type PageHeaderSubtitleProps = {
  children: React.ReactNode;
};

const styles = {
  pageHeaderSubtitle: {
    fontSize: '15px',
    color: (theme: Theme) => theme.palette?.primary.light,
    fontWeight: '600',
    margin: 0,
    padding: '0px 10px',
  },
};

function PageHeaderSubtitle({ children }: PageHeaderSubtitleProps) {
  return (
    <Typography variant="h4" sx={styles.pageHeaderSubtitle}>
      {children}
    </Typography>
  );
}

export default PageHeaderSubtitle;
