// Validation functions
import { handleGetClientSessionInfo } from './ClientServiceTS';

function verifyLength(value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}
function verifyNumber(value) {
  const numberRex = /^[0-9]+$/;
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}
function verifyFloatNumber(value) {
  const numberRex = /^[0-9]*[.]?[0-9]+$/;
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}
function decimalValidationAll(array, property) {
  let valid = true;
  const decimalRex = /^[0-9]+(.[0-9]{1,2})?$/;
  array.forEach((obj) => {
    if (!decimalRex.test(obj[property])) {
      valid = false;
    }
  });
  return valid;
}

function verifyUrl(value, secure = false) {
  try {
    // eslint-disable-next-line no-new
    new URL(value);
    const protocolType = secure ? 'https' : 'http|https';
    const protocolRegex = new RegExp(protocolType, 'g');
    const protocol = protocolRegex.test(value);
    const containsSpaces = /\s/g.test(value.trim());
    if (!protocol || containsSpaces) {
      throw Error;
    }
    return true;
  } catch (_) {
    return false;
  }
}

function verifyCFID(value) {
  const cfidRex = /^CFID:([a-zA-Z0-9]{32})$/;
  return cfidRex.test(value);
}

function verifyClickImpressionURL(value, stateName = 'url') {
  const valueLowercase = value.toLowerCase();
  if (stateName === 'clickURL') {
    const impressionStrings = ['impression', 'trackimp', 'cn=display&c=19'];
    return /([^a-zA-Z])imp([^a-zA-z]|$)/g.test(valueLowercase) ||
      impressionStrings.some((impression) => valueLowercase.includes(impression))
      ? 'warning'
      : true;
  }
  if (stateName === 'tracker') {
    const clickStrings = ['=click', 'trackclk', 'cn=trd'];
    return clickStrings.some((click) => valueLowercase.includes(click)) || !verifyUrl(value, true)
      ? 'warning'
      : true;
  }
  return false;
}

const verifyJSTrackerURL = (value = '', jsApprovedDomains = []) => {
  if (!value) {
    return { state: 'not required' };
  }
  const valueLowerCase = value.toLowerCase();
  const isValidURL = verifyUrl(value, true);
  const isValid = jsApprovedDomains.some((domain) => valueLowerCase.includes(domain));
  const isTooLong = verifyLength(value, 1024);
  if (isValid && isValidURL && !isTooLong) {
    return { state: 'success', message: '' };
  }
  if (!isValidURL) {
    return { state: 'error', message: 'URL must be valid and secure (HTTPS)' };
  }
  if (isTooLong) {
    return { state: 'error', message: 'You have exceeded the max length of this field.' };
  }
  return {
    state: 'error',
    message: `We currently only accept js trackers from the following domains: ${jsApprovedDomains.join(
      ', ',
    )}`,
  };
};

function verifyEmail(value) {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

function verifyPhone(value, length) {
  return verifyNumber(value) && !verifyLength(value, length + 1);
}

function validate(element, stateName, required, type, stateNameEqualTo, maxValue) {
  let result = 'error';
  let message = 'This field is invalid';
  const value = element.target ? element.target.value.trim() : element;
  const verifyTracker = verifyClickImpressionURL(value, stateName);
  if (value === '') {
    if (required) {
      result = 'error';
      message = 'This field is required';
    } else {
      result = 'not required';
    }
  } else {
    switch (type) {
      case 'equalTo':
        if (this.compare(value, this.state[stateNameEqualTo])) {
          result = 'success';
        }
        break;
      case 'checkbox':
        if (element.target.checked) {
          result = 'success';
        }
        break;
      case 'number':
        if (verifyNumber(value)) {
          result = 'success';
        }
        break;
      case 'floatNumber':
        if (verifyFloatNumber(value)) {
          result = 'success';
        }
        break;
      case 'floatNumberMax':
        if (verifyFloatNumber(value) && value <= stateNameEqualTo) {
          result = 'success';
        }
        break;
      case 'floatNumberRange':
        if (verifyFloatNumber(value) && value >= stateNameEqualTo && value <= maxValue) {
          result = 'success';
        }
        break;
      case 'floatNumberRangeWithDecimalPoint':
        if (
          verifyFloatNumber(value) &&
          value >= stateNameEqualTo &&
          value <= maxValue &&
          value.match(/^[0-9]+\.[0-9]{2}$/)
        ) {
          result = 'success';
        }
        break;
      case 'length':
        if (verifyLength(value, stateNameEqualTo)) {
          result = 'success';
        }
        break;
      case 'max-length':
        if (!verifyLength(value, stateNameEqualTo + 1)) {
          result = 'success';
        } else {
          message = `The value of this field can not exceed ${stateNameEqualTo} characters`;
        }
        break;
      case 'min-length':
        if (verifyLength(value, stateNameEqualTo)) {
          result = 'success';
        }
        break;
      case 'url':
        if (verifyUrl(value) && !verifyLength(value, stateNameEqualTo + 1)) {
          result = 'success';
        }
        break;
      case 'clickImpressionURL':
        if (verifyUrl(value) && !verifyLength(value, stateNameEqualTo + 1) && verifyTracker) {
          result = verifyTracker === 'warning' ? 'warning' : 'success';
        }
        break;
      case 'min-value':
        if (verifyNumber(value) && value >= stateNameEqualTo) {
          result = 'success';
        }
        break;
      case 'max-value':
        if (verifyNumber(value) && value <= stateNameEqualTo) {
          result = 'success';
        }
        break;
      case 'range':
        if (verifyNumber(value) && value >= stateNameEqualTo && value <= maxValue) {
          result = 'success';
        }
        break;
      case 'email':
        if (verifyEmail(value)) {
          result = 'success';
        }
        break;
      case 'phone':
        if (verifyPhone(value, stateNameEqualTo)) {
          result = 'success';
        }
        break;
      case 'video':
        if (value.startsWith('CFID:') && verifyCFID(value)) {
          result = 'success';
        } else if (verifyUrl(value) && !verifyLength(value, stateNameEqualTo + 1)) {
          result = 'success';
        }
        break;
      default:
        break;
    }
  }
  // Add extra validations by field
  switch (stateName) {
    case 'creativeAdText':
      // Exclamation marks
      if (value.indexOf('!') !== -1) {
        result = 'error';
        message = " We don't generally allow exclamation marks!. ";
      }
      // All in capitals or includes non latin characters (which we cant test for uppercase)
      if (value && !value.match(/[^\u0020-\u007f]/) && value === value.toUpperCase()) {
        result = 'error';
        message += ' Ad text ALL IN CAPS is not allowed. ';
      }
      break;
    case 'vastURL':
      // Not vimeo nor youtube
      if (value === '') {
        message = 'Video URL is required and must be valid, including HTTP or HTTPS';
      }
      if (
        value.match(
          /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w/]+)([?].*)?$/gim,
        )
      ) {
        result = 'error';
        message = 'Only VAST tags and Cloudflare URLs supported';
      }
      break;
    default:
      break;
  }

  return {
    state: result,
    message,
  };
}

async function checkClientSession({ accountId, accountType }) {
  return handleGetClientSessionInfo()
    .then((sessionData) => {
      if (!sessionData) {
        return { error: 'no response from api' };
      }
      if (sessionData.error) {
        return sessionData;
      }
      const sessionAccountType = sessionData.accountType;

      return {
        sessionMatch:
          accountId?.toString() === sessionData?.id?.toString() &&
          accountType === sessionData?.accountType,
        sessionClientId: sessionData[sessionAccountType].id,
        sessionClientName: sessionData[sessionAccountType].name,
      };
    })
    .catch(() => {
      return { error: 'Unknown Error' };
    });
}
/**
 * @typedef {{capAmount: string,capPeriod: string, paced: 0|1}[]} Caps
 * @param {Caps} capBudgets
 * @param {string | null | undefined} endDate
 * @param {boolean | undefined} isAdManager
 * @returns
 */
function validateCapBudget(capBudgets, endDate = null, isAdManager = false) {
  // Basic validation: non empty and positive numeric
  const basicValidation =
    capBudgets.length > 0 &&
    capBudgets.length ===
      capBudgets.filter((cap) => !Number.isNaN(Number(cap.capAmount)) && Number(cap.capAmount) > 0)
        .length;
  if (!basicValidation) {
    return {
      state: 'error',
      message: 'Value must be numeric and greater than 0',
    };
  }
  // check for max 2 decimals
  const decimalValid = decimalValidationAll(capBudgets, 'capAmount');
  if (!decimalValid) {
    return {
      state: 'error',
      message: 'Values with decimals must be to maximum 2 decimal places',
    };
  }
  // Admanger is doing it's seperate validation of total caps to improve ux
  if (!isAdManager) {
    // Paced total budget requires a campaign end date
    const totalCap = capBudgets.filter((cap) => cap.capPeriod === 'O' && cap.paced).length > 0;
    const noEndDate = endDate === null || endDate === '';
    const pacedValid = !(totalCap && noEndDate);
    if (!pacedValid) {
      return {
        state: 'error',
        message: 'Paced total budget requires a campaign end date',
      };
    }
  }
  const dailyBudgets = capBudgets.filter((cap) => cap.capPeriod === 'D').length;
  const weeklyBudgets = capBudgets.filter((cap) => cap.capPeriod === 'W').length;
  const monthlyBudgets = capBudgets.filter((cap) => cap.capPeriod === 'M').length;
  const totalBudgets = capBudgets.filter((cap) => cap.capPeriod === 'O').length;
  if (dailyBudgets > 1 || weeklyBudgets > 1 || monthlyBudgets > 1 || totalBudgets > 1) {
    return {
      state: 'error',
      message: 'Budgets are limited to only one daily, weekly, monthly and total caps',
    };
  }
  return {
    state: 'success',
    message: '',
  };
}
function validateOverallCapBudget(rawCapBudgets = []) {
  // Filter budgets to remove deleted ones (status === '-')
  const capBudgets = rawCapBudgets.filter((cap) => !cap.status || cap.status !== '-');
  // Basic validation: non empty and positive numeric
  const basicValidation = capBudgets.every((cap) => !Number.isNaN(cap.rawCap) && cap.rawCap > 0);

  if (!basicValidation) {
    return {
      state: 'error',
      message: 'Values must be numeric and positive',
    };
  }
  const MAX_API_SAFE_NUMBER = 999999999; // 999_999_999
  const maxNumValidation =
    capBudgets.length === capBudgets.filter((cap) => cap.rawCap <= MAX_API_SAFE_NUMBER).length;
  if (!maxNumValidation) {
    return {
      state: 'error',
      message:
        'One or more of your budgets exceeds the maximum budget allowed. Please reduce your budget to 999,999,999 or below.',
    };
  }
  const decimalValid = decimalValidationAll(capBudgets, 'rawCap');

  if (!decimalValid) {
    return {
      state: 'error',
      message: 'Values with decimals must be to maximum 2 decimal places',
    };
  }
  const totalBudgets = capBudgets.filter((cap) => cap.rawPeriod === 'O');
  if (totalBudgets.length === 1 && !totalBudgets[0].startDate) {
    return {
      state: 'error',
      message: 'Total period requires start date',
    };
  }
  return {
    state: 'success',
    message: '',
  };
}

function validateStartEndDate(launchDate, endDate, noEndDate, noLaunchDate) {
  if (endDate !== '' && launchDate !== '') {
    const processedLaunchDate = typeof launchDate === 'string' ? new Date(launchDate) : launchDate;
    const processedEndDate = typeof endDate === 'string' ? new Date(endDate) : endDate;
    if (processedLaunchDate >= processedEndDate) {
      const timeframeErrorState = {
        state: 'error',
        message: 'Start date can not be later than end date',
      };
      return {
        launchDateState: timeframeErrorState,
        endDateState: timeframeErrorState,
      };
    }
  }
  const defaultNotRequiredState = {
    state: 'not required',
    message: '',
  };
  const defaultSuccessState = {
    state: 'success',
    message: '',
  };
  let endDateState = defaultNotRequiredState;
  if (!endDate && !noEndDate) {
    endDateState = {
      state: 'error',
      message: 'End date is required. Please select a date or check the No end date box',
    };
  }
  let launchDateState = defaultNotRequiredState;
  if (!launchDate && !noLaunchDate) {
    launchDateState = {
      state: 'error',
      message: 'Launch date is required. Please select a date and time or check the ASAP box',
    };
  }
  return {
    launchDateState: launchDate ? defaultSuccessState : launchDateState,
    endDateState: endDate ? defaultSuccessState : endDateState,
  };
}

const validateAdformOrCreativeStates = (objectOfStates) =>
  !Object.keys(objectOfStates).filter(
    (state) =>
      state.endsWith('State') &&
      (objectOfStates[state].state === '' || objectOfStates[state].state === 'error'),
  ).length;

function validateBlockingTag(blockingTag) {
  if (!blockingTag) {
    return {
      state: 'not required',
      message: '',
    };
  }
  try {
    const url = new URL(blockingTag);
    const { searchParams } = url;
    if (url?.host?.toLowerCase() !== 'cdn.doubleverify.com') {
      return {
        state: 'error',
        message: 'Blocking tag must be a valid DoubleVerify tag',
      };
    }
    if (url?.pathname?.toLowerCase() !== '/dvbs_src.js') {
      return {
        state: 'error',
        message: 'Blocking tag must be a valid DoubleVerify tag',
      };
    }

    if (!searchParams.get('ctx')) {
      return {
        state: 'error',
        message: 'Blocking tag must have ctx query parameter',
      };
    }
    if (!searchParams.get('cmp')) {
      return {
        state: 'error',
        message: 'Blocking tag must have cmp query parameter',
      };
    }
    if (!searchParams.get('sid')) {
      return {
        state: 'error',
        message: 'Blocking tag must have sid query parameter',
      };
    }
    if (!searchParams.get('plc')) {
      return {
        state: 'error',
        message: 'Blocking tag must have plc query parameter',
      };
    }
  } catch {
    return {
      state: 'error',
      message:
        'Blocking tag is not a valid url, must be of format: https://cdn.doubleverify.com/dvbs_src.js?ctx=...&cmp=...&sid=...&plc=... (not including <script> tags)',
    };
  }
  return {
    state: 'success',
    message: '',
  };
}

export default {
  validate,
  checkClientSession,
  validateCapBudget,
  validateOverallCapBudget,
  validateStartEndDate,
  verifyJSTrackerURL,
  validateAdformOrCreativeStates,
  validateBlockingTag,
};
