/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */
import React from 'react';
import { Box, SxProps, keyframes, useTheme } from '@mui/material';

/**
  +----------------+
  | Initial State  |
  +----------------+
         |
         v
  +----------------+   false  
  |   loading      |----------+
  +----------------+          |
         |                    | 
         | true               |
         |                    |
         v                    |
  +-----------------+         |
  | awaitDelay      |         |
  +-----------------+         |
         |                    |
         | delay completed    |
         v                    |
  +----------------+   false  |
  |   loading      |----------+
  +----------------+          |
         |                    | 
         | true               |
         |                    |
         v                    |
  +----------------+          |
  | showSpinner    |          |
  +----------------+          |
         |                    |
         | minimumTime        |
         | completed          |
         v                    |
  +----------------+   false  |
  |   loading      |----------+
  +----------------+          |
         |                    | 
         | true               |
         |                    |
         v                    |
  +----------------+          |
  | keep showing   |          |
  | spinner until  |          |
  | loading = false|          |
  +----------------+          |
         |                    |
         |                    |
         |                    |
         v                    |
  +----------------+          |
  |  show children | <--------+
  +----------------+
          |
          |
          v
  +----------------+          
  |     repeat     | 
  +----------------+
 */

const pathD = `
    M1.5,1.5
    L1.5,40.8
    L39.3,40.8
    L39.3,11.1
    L13.1,11.1
    L13.1,38.5
    L13,50.4
    L26.4,50.4
    C34.7,50.4,41.2,48.2,45.7,43.8
    C50.1,39.4,52.5,33.4,52.5,25.9
    C52.5,18.4,50.1,12.4,45.7,8
    C41.2,3.6,34.7,1.5,26.5,1.5
    L0,1.5`;

type Props = {
  /**
   * Whether to show the spinner or not
   */
  loading: boolean;
  /**
   * The children to show when not loading
   */
  children?: React.ReactNode;
  /**
   * The delay in ms before showing the spinner
   *
   * `Default 200`
   */
  delay?: number;
  /**
   * The minimum time in ms to show the spinner for
   *
   * `Default 1000`
   */
  minimumTime?: number;
  /**
   * The sx props to apply to the spinner wrapper
   *
   * Change the width/height to change the size of the spinner
   *
   * Default styles: `{ width: '60px', height: '60px', display: 'block', margin: '20px auto' }`
   */
  sx?: SxProps;
};

/**
 * WARNING: Be careful when making changes to this component. During development, there were bugs where the animation
 * would reset halfway through, mainly due to incorrect handling of useEffects. Unfortunately, both I and ChatGPT
 * could not figure out how to write an automated test for checking if the animation is working correctly,
 * so manual testing is necessary to ensure smooth and uninterrupted animation.
 */
type Status = 'awaitDelay' | 'showSpinner' | 'showChildren';

export default function DianomiSpinner({
  loading,
  children,
  delay = 200,
  minimumTime = 1000,
  sx = {},
}: Props) {
  // Need to use a ref for loading so we don't get a stale closure over it inside the setTimeouts.
  // by using refs, reading it in the timeouts will always get the latest value.
  const loadingRef = React.useRef(loading);
  const delayTimeoutToggle = React.useRef<NodeJS.Timeout | null>(null);
  const timingsRef = React.useRef({ delay, minimumTime });
  const [status, setStatus] = React.useState<Status>(loading ? 'awaitDelay' : 'showChildren');

  // make sure we update the ref every time props.loading changes
  React.useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  // These useEffects are a little fragile, change with caution. read the comments carefully.
  React.useEffect(() => {
    // if loading is true, start the delay timeout,
    // ie, don't show anything until the delay has completed    // loading states doesn't restart the delay/minimum time combo
    if (loading && !delayTimeoutToggle.current) {
      delayTimeoutToggle.current = setTimeout(() => {
        // the delay has completed here, so if loading is still true, show the spinner
        // otherwise, show the children
        if (loadingRef.current) {
          // if we show the spinner, the other useEffect will handle the minimum time
          // and also setting the delayTimeoutToggle to null
          // (so it can restart if loading becomes true again later)
          setStatus('showSpinner');
        } else {
          // if loading is false, we don't want to show the spinner, so we need to
          // clear the delayTimeoutToggle ref so it can restart if loading becomes true again later
          delayTimeoutToggle.current = null;
          setStatus('showChildren');
        }
      }, timingsRef.current.delay);
      setStatus('awaitDelay');
      return () => {
        if (delayTimeoutToggle.current) {
          clearTimeout(delayTimeoutToggle.current);
          // delayTimeoutToggle.current = null;
        }
      };
    }
    // if loading is false, check if the spinner is showing,
    // and if so, carry on showing it, otherwise show children
    // this will allow the children to show if the delay hasn't completed yet
    if (!loading) {
      setStatus((oldStatus) => (oldStatus === 'showSpinner' ? oldStatus : 'showChildren'));
      // delayTimeoutToggle.current = null;
    }
    return undefined;
  }, [loading]);

  // this is handling the minimum time spinner should show for.
  // this needs to be done in a separate useEffect to the above
  React.useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;
    if (status === 'showSpinner') {
      spinnerTimeout = setTimeout(() => {
        // always clear the delayTimeout ref, so when finally loading becomes false, we don't
        // do another delay/show spinner combo
        delayTimeoutToggle.current = null;
        setStatus('showChildren');
      }, timingsRef.current.minimumTime);
      return () => {
        clearTimeout(spinnerTimeout);
      };
    }
    return undefined;
  }, [status]);

  if (!loading && status === 'showChildren') {
    return <>{children}</>;
  }
  if (loading && status === 'awaitDelay') {
    return <div key="loading-progress" data-testid="loading-progress await-delay" />;
  }
  if (loading || status === 'showSpinner') {
    return <DianomiLogoLoadingSpinner key="loading-progress" animationTime={minimumTime} sx={sx} />;
  }
  // if all else fails, show children
  return <>{children}</>;
}

const DianomiLogoLoadingSpinner = React.forwardRef(
  (props: { animationTime?: number; sx?: SxProps }, ref) => {
    const pathRef = React.useRef<SVGPathElement | null>(null);
    const [pathLength, setPathLength] = React.useState(0);
    const theme = useTheme();

    React.useEffect(() => {
      if (!pathRef.current) return;
      setPathLength(pathRef.current.getTotalLength?.());
    }, []);

    const animation = keyframes`
    0%,
    10% {
      stroke-dashoffset: ${pathLength};
    }
    45%, 
    55% {
      stroke-dashoffset: 0;
    }
    90%,
    100% {
      stroke-dashoffset: -${pathLength};
    }
  `;

    // we double the props.animation time here as the animation is 50% in and 50% out and we want to have
    // make sure the animation fills at least once.
    const timeInSeconds = Math.min((props.animationTime ?? 1000) * 2, 2000) / 1000;
    const animationString = `${animation} ${timeInSeconds}s ease-in-out infinite`;

    return (
      <Box
        role="progressbar"
        sx={{ width: '60px', height: '60px', display: 'block', margin: '20px auto', ...props.sx }}
        ref={ref}
        data-testid="loading-progress spinner"
      >
        <Box
          component="svg"
          sx={{ display: 'block' }}
          width="100%"
          height="100%"
          viewBox="0 0 55 55"
        >
          <Box
            component="path"
            d={pathD}
            stroke={theme.palette.grey[300]}
            sx={{ zIndex: 1 }}
            fill="none"
            strokeWidth="3"
          />
          <Box
            component="path"
            sx={{
              strokeDasharray: pathLength,
              strokeDashoffset: pathLength,
              animation: animationString,
              padding: 0,
              margin: 0,
            }}
            ref={pathRef}
            id="d-path"
            d={pathD}
            stroke="currentColor"
            strokeWidth="3"
            fill="none"
          />
        </Box>
      </Box>
    );
  },
);
