import React from 'react';
import TabsBase, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { Theme } from '@mui/material/styles';

const styles = {
  tabs: {
    padding: '0',
  },
  root: {
    minHeight: 'unset !important',
    backgroundColor: (theme: Theme) => theme.palette?.primary.main,
  },
};

interface TabsProps extends MuiTabsProps {
  value: number;
  onChange: (event: React.SyntheticEvent, value: number) => void;
  children: React.ReactNode;
}

function Tabs({ value, onChange, ...props }: TabsProps) {
  const defaultProps = {
    indicatorColor: 'primary',
    textColor: 'primary',
  } as const;

  const mergedProps = { ...defaultProps, ...props };

  return (
    <TabsBase
      value={value}
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...mergedProps}
      sx={{ ...styles.tabs, ...styles.root, ...props.sx }}
    />
  );
}

export default Tabs;
