// Is this file used anymore?

/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { logout as logUserOut } from '~/services/UserService';

type SearchParamsObj = {
  referer?: string;
};

const useSession = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['SESSION_STARTED', 'loginSeen']);
  const location = useLocation();

  const removeAndRedirect = useCallback(
    async (timeout: number) => {
      setTimeout(() => {
        removeCookie('SESSION_STARTED', { path: '/' });
        removeCookie('loginSeen', { path: '/' });
        const referer = new URLSearchParams(location.search).get('referer');
        const searchParamObj: SearchParamsObj = {};
        if (referer) {
          searchParamObj.referer = referer;
        } else if (location.pathname !== '/' && location.pathname !== '/no-session') {
          searchParamObj.referer = `${location.pathname}${location.search}`;
        }
        // if location.pathname does equal '/admin' then we don't want any searchparams

        const oldLocation = new URLSearchParams(searchParamObj).toString();
        // replace so memory gets emptied (ie make sure query client is empty on next login)
        window.location.assign(`/login?${oldLocation}`);
      }, timeout);
    },
    [removeCookie, location.pathname, location.search],
  );

  const logout = useCallback(
    async (timeout = 500) => {
      // comment this next line out when debugging login behaviors
      const loggedOut = await logUserOut();
      if (
        loggedOut &&
        typeof loggedOut === 'object' &&
        ('logged_out' in loggedOut || ('error' in loggedOut && loggedOut.error === 'no session'))
      ) {
        await removeAndRedirect(timeout).catch(() => null);
        sessionStorage.removeItem('loginSeen');
      }
    },
    [removeAndRedirect],
  );

  const setSessionCookie = useCallback(() => {
    setCookie('SESSION_STARTED', 1, { path: '/', secure: true });
  }, [setCookie]);

  return {
    session: cookies.SESSION_STARTED,
    logout,
    setSessionCookie,
    removeCookie,
  } as const;
};

export default useSession;
