const globalStyles = {
  html: {
    fontFamily: ['Nexa', 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  body: {
    fontFamily: ['Nexa', 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  a: {
    textDecoration: 'none',
    color: '#3a7eff',
    '&:hover': {
      color: '#3a7eff',
      textDecoration: 'none',
    },
  },
  label: {
    color: '#aaa',
  },
  li: {
    listStyle: 'none',
    textDecoration: 'none',
  },
  button: {
    fontFamily: ['Nexa', 'Source Sans Pro', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  h1: {
    fontSize: '1.75em',
  },
  h2: {
    fontSize: '1.25em',
  },
  h3: {
    fontSize: '1.125em',
  },
};

export default globalStyles;
