import utils from '~/layouts/Admin/Admin.utils';
import { useQuery } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import useSessionPlQuery from '../useSessionPlQuery';

const useAccountsQuery = () => {
  const { data: sessionData } = useSessionPlQuery();
  const { setTimedNotification } = useNotifications();
  return useQuery({
    queryKey: ['accounts.pl', sessionData?.id, sessionData?.isPartner],
    queryFn: async () => {
      const data = await utils.handleGetAccounts();
      if (!data) {
        setTimedNotification(
          'danger',
          'Error loading accounts data, please reload page. If the issue persists, contact support.',
          8000,
        );
      }
      return data;
    },
    refetchOnWindowFocus: false,
    // staleTime: 1000 * 60 * 30, // 30 minutes stale time
    enabled: !!sessionData?.id,
  });
};

export default useAccountsQuery;
