/* eslint-disable camelcase */
import { Navigate, useLocation } from 'react-router-dom';
import * as React from 'react';

export default function HandleRedirects() {
  const location = useLocation();
  const { pathname, search } = location;
  const searchParams = new URLSearchParams(search);
  const searchParamsObj = Object.fromEntries(searchParams.entries());
  const route = pathname.replace('/admin/', '');
  const { client_id, partner_id } = searchParamsObj;

  if (!client_id && !partner_id) {
    return <Navigate to="/" replace />;
  }

  if (client_id) {
    const copy = { ...searchParamsObj };
    delete copy.client_id;
    const newSearch = new URLSearchParams(copy).toString();
    return <Navigate to={`/client/${client_id}/${route}?${newSearch}`} replace />;
  }
  if (partner_id) {
    const copy = { ...searchParamsObj };
    delete copy.partner_id;
    const newSearch = new URLSearchParams(copy).toString();
    return <Navigate to={`/partner/${partner_id}/${route}?${newSearch}`} replace />;
  }

  return <Navigate to="/" replace />;
}
