/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * for use in default cases of switch statements.
 * This function will throw an error if it is ever called.
 * and also gives a nice ts warning if you forget to add a case to a switch statement
 */
export function throwNever(_value: never, message = 'This should never happen'): never {
  throw new Error(message);
}

type Options = RequestInit & {
  params?: Record<string, string> | URLSearchParams;
  ignoreBasePath?: boolean;
  useNodeEndpoint?: boolean;
  throwOnHTTPError?: boolean;
  returnOriginalResponse?: boolean;
  shouldUseBasePath?: boolean;
};

export function CreateCustomFetcher({
  basePath,
  selfServeURL,
  getCSRFToken,
  setToken,
  defaultHeaders,
  fetchOrigin,
}: {
  basePath: string;
  selfServeURL: string;
  getCSRFToken: () => string;
  setToken: (token: string) => void;
  defaultHeaders?: HeadersInit;
  fetchOrigin?: string;
}) {
  async function customFetch<T = unknown>(
    endpoint: string,
    {
      body,
      params,
      ignoreBasePath = false,
      useNodeEndpoint = false,
      throwOnHTTPError = true,
      returnOriginalResponse = false,
      ...options
    }: Options = {},
  ) {
    const csrfToken = getCSRFToken();
    /**
     * this is to get the stack trace of the original caller
     * rather than the stack of this function
     */

    const config: RequestInit = {
      method: body ? 'POST' : 'GET',
      ...options,
      headers: {
        ...defaultHeaders,
        ...(csrfToken ? { 'X-XSRF-TOKEN': csrfToken } : {}),
        ...options.headers,
      },
    };
    if (body) {
      config.body = body;
    }

    let newEndpoint = endpoint;
    let newParams = params;

    if (typeof window !== 'undefined') {
      const useLiveEndpoints = Number(localStorage.getItem('live-endpoints'));
      if (useLiveEndpoints) {
        newParams = addToParams(newParams, 'live', 'true');
      }
    }
    if (useNodeEndpoint) {
      newParams = addToParams(newParams, 'node', 'true');
    }

    if (newParams) {
      newEndpoint += `?${new URLSearchParams(newParams)}`;
    }

    // These two endpoints are not in the selfserve API
    const shouldUseBasePath = ['/geneus.pl', '/genienav.pl'].includes(endpoint);
    if (ignoreBasePath) {
      // don't do anything, use the endpoint as is
    } else if (useNodeEndpoint) {
      newEndpoint = `/preview${newEndpoint}`;
    } else if (shouldUseBasePath) {
      newEndpoint = `${basePath}${newEndpoint}`;
    } else {
      newEndpoint = `${selfServeURL}${newEndpoint}`;
    }

    // check if url is relative:
    if (!newEndpoint.startsWith('http') && fetchOrigin) {
      newEndpoint = `${fetchOrigin}${newEndpoint}`;
    }

    const request = new Request(newEndpoint, config);

    return fetch(request)
      .then(async (response) => {
        const clonedResponse = response.clone();
        if (response.ok || !throwOnHTTPError) {
          if (response.headers.get('Content-Type')?.includes('application/json')) {
            return {
              data: await response.json(),
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
              body: returnOriginalResponse ? clonedResponse.body : undefined,
            };
          }
          if (response.headers.get('Content-Type')?.includes('application/pdf')) {
            return {
              data: await response.blob(),
              status: response.status,
              statusText: response.statusText,
              headers: response.headers,
              body: returnOriginalResponse ? clonedResponse.body : undefined,
            };
          }
          return {
            data: await response.text(),
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            body: returnOriginalResponse ? clonedResponse.body : undefined,
          };
        }
        return Promise.reject(new Error(response.statusText, { cause: response }));
      })
      .then((res) => {
        const { data, ...rest } = res;
        if (endpoint.includes('session.pl') && (data as any).login) {
          // collect CSRF token
          setToken((data as any).login.csrf_token || '');
        }
        return { data, ...rest } as { data: T; status: number; statusText: string };
      });
  }
  /**
   * Passing a Generic **isn't** typesafe! it's just an easier way of adding a return type (using 'as')
   */
  customFetch.get = <T = unknown>(endpoint: string, options?: Options) =>
    customFetch<T>(endpoint, { ...options, method: 'GET' });
  /**
   * Passing a Generic **isn't** typesafe! it's just an easier way of adding a return type (using 'as')
   */
  customFetch.post = <T = unknown>(
    endpoint: string,
    body: BodyInit | null | undefined,
    options?: Options,
  ) => customFetch<T>(endpoint, { ...options, body, method: 'POST' });
  /**
   * Passing a Generic **isn't** typesafe! it's just an easier way of adding a return type (using 'as')
   */
  customFetch.put = <T = unknown>(
    endpoint: string,
    body: BodyInit | null | undefined,
    options?: Options,
  ) => customFetch<T>(endpoint, { ...options, body, method: 'PUT' });
  /**
   * Passing a Generic **isn't** typesafe! it's just an easier way of adding a return type (using 'as')
   */
  customFetch.delete = <T = unknown>(endpoint: string, options?: Options) =>
    customFetch<T>(endpoint, { ...options, method: 'DELETE' });
  return customFetch;
}

function addToParams(params: Options['params'], name: string, value: string) {
  if (params instanceof URLSearchParams) {
    params.append(name, value);
  } else {
    return { ...params, [name]: value };
  }
  return params;
}
