import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { customFetch } from '../../../utils/customFetch';
import { useAccountParams } from '../../../hooks/useAccountParams';

export type AudienceData = {
  code: string;
  cpm: number | null;
  description: string | null;
  id: number;
  impressions_30_days: number;
  name: string;
  owner: string;
  pixel_tag: string | null;
  source: string;
  type: string | null;
  users_30_days: number;
  partner_name: string | null;
  data_provider_partner_id: number | null;
};

export type AudienceDataMap = {
  first_party: AudienceData[];
  dianomi: AudienceData[];
  liveramp: AudienceData[];
  neustar: AudienceData[];
  unknown: AudienceData[];
};

type ErrorResponse = {
  message: string;
  success: 0;
};

type SuccessResponse = {
  success: 1;
  data: AudienceDataMap;
};

export function getClientAudienceQueryOptions({ accountId }: { accountId?: string | number }) {
  return {
    queryKey: ['client-audience', String(accountId)],
    queryFn: async () => {
      if (!accountId) throw new Error('Account ID is required');
      const res = await customFetch<ErrorResponse | SuccessResponse>(
        `/authenticated/client/${accountId}/audiences/list`,
        {
          useNodeEndpoint: true,
          throwOnHTTPError: false,
        },
      );
      if (res.data?.success === 0) {
        throw new Error(res.data.message);
      }
      return res.data.data;
    },
    enabled: !!accountId,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    keepPreviousData: true,
  } satisfies QueryObserverOptions;
}

export function useClientAudienceQuery() {
  const accountDetails = useAccountParams();
  return useQuery(getClientAudienceQueryOptions({ accountId: accountDetails?.accountId }));
}
