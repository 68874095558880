/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import styles from './styles';

interface TitleProps extends TypographyProps {
  children?: React.ReactNode;
}

/**
 * The `<SectionTitle />` component is to be used as a section title to differentiate between areas of a page.
 * This component is not to be used in the header, as the styling here differs.
 * Use case example: We have two tables on a single page where we want to use titles to indicate each tables purpose.
 * @example
 *  <SectionTitle>
      {prop.title}
    </SectionTitle>
 */

export function Title({ children, ...props }: TitleProps) {
  return (
    <Typography variant="h3" sx={styles.pageTitle} {...props}>
      {children}
    </Typography>
  );
}

export default Title;
