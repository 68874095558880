import React from 'react';
import theme from '~/theme';
import Button, { ButtonProps } from '@mui/material/Button';
import { FormHelperText } from '@mui/material';
import styles from './styles';

interface Props extends ButtonProps {
  error?: boolean;
  errorMessage?: string | null;
  disabled?: boolean;
  name?: string;
  wrap?: boolean;
  isValid?: boolean;
  color?: 'primary' | 'secondary' | 'error';
}

const StyledButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      style,
      children,
      onClick,
      error = false,
      errorMessage = undefined,
      color = undefined,
      disabled = false,
      isValid = undefined,
      name,
      wrap,
      ...rest
    }: Props,
    ref,
  ) => {
    return (
      <>
        <Button
          onClick={onClick}
          style={style}
          disabled={disabled}
          name={name}
          ref={ref}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          aria-describedby={`${name}-helper-text`}
          sx={{
            ...styles.button,
            ...(wrap && styles.wrap),
            ...(isValid && styles.isValid),
            ...(color && styles[color]),
            ...rest.sx,
          }}
        >
          {children}
        </Button>
        <FormHelperText
          sx={error ? { ...theme.defaultStyles.errorContainer } : { display: 'block' }}
          error={error}
          id={`${name}-helper-text`}
        >
          {errorMessage}
        </FormHelperText>
      </>
    );
  },
);

export default StyledButton;
