import { Box, Fade } from '@mui/material';
import * as React from 'react';
import PageHeaderSubtitle from '~/components/Typography/PageHeaderSubtitle';
import PageHeaderTitle from '~/components/Typography/PageHeaderTitle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AdminNavbar from '~/components/Commons/Navbars/AdminNavbar';

export default function UnderMaintenancePage() {
  return (
    <Box
      sx={{
        color: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <AdminNavbar isAuthBar />
      <Fade in>
        <Box
          width="100%"
          maxWidth="500px"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
            padding: '30px',
            borderRadius: '4px',
            height: '100%',
            marginTop: '200px',
          })}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <PageHeaderTitle>Under Maintenance</PageHeaderTitle>
            <EngineeringIcon fontSize="large" />
          </Box>
          <Box component="hr" sx={{ margin: '24px 0' }} />
          <PageHeaderSubtitle>
            MyDianomi is currently under maintenance. We should be back up and running soon. Sorry
            for any inconvenience caused.
          </PageHeaderSubtitle>
        </Box>
      </Fade>
    </Box>
  );
}
