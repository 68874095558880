import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import useWindowSize from '~/hooks/useWindowSize';
import DialogInfoTooltip from '../DialogInfoTooltip';
import DialogButtons from './DialogButtons';
import ActionConfirmDialogStyles from './styles';

type Props = {
  handleDialogClose: (e?: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  title: React.ReactNode;
  message: React.ReactNode;
  showCloseButton?: boolean;
  handleDialogConfirm?: ((e: React.MouseEvent<HTMLElement>) => void) | undefined | null;
  acceptText?: string;
  actions?: boolean;
  isSubmitting?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  tooltipTitle?: string | undefined;
  tooltipContent?: React.ReactElement | undefined;
  cancelText?: string;
  fullHeight?: boolean;
};

function ActionConfirmDialog({
  handleDialogClose,
  open = false,
  title,
  message,
  showCloseButton = true,
  handleDialogConfirm,
  acceptText = 'Confirm',
  actions = true,
  maxWidth = 'md',
  fullWidth = false,
  isSubmitting = false,
  tooltipTitle = '',
  tooltipContent = undefined,
  cancelText = undefined,
  fullHeight = false,
}: Props) {
  const { isMobile } = useWindowSize();

  return (
    <Dialog
      onClose={(e) => handleDialogClose(e as React.MouseEvent<HTMLElement>)}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={isMobile}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={fullHeight ? { sx: { height: '100%' } } : {}}
      sx={{ zIndex: 3000 }}
    >
      <DialogTitle id="customized-dialog-title" sx={ActionConfirmDialogStyles.titleWrapper}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          {title}
          {tooltipContent && (
            <DialogInfoTooltip title={tooltipTitle} forceZIndex={3000}>
              {tooltipContent}
            </DialogInfoTooltip>
          )}
        </Box>
        <IconButton
          aria-label="Close"
          onClick={handleDialogClose}
          data-testid="cross-button"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={ActionConfirmDialogStyles.content}>{message}</DialogContent>
      {actions && (
        <DialogButtons
          showCloseButton={showCloseButton}
          handleDialogConfirm={handleDialogConfirm}
          acceptText={acceptText}
          isSubmitting={isSubmitting}
          cancelText={cancelText}
          handleDialogClose={handleDialogClose}
        />
      )}
    </Dialog>
  );
}

export default ActionConfirmDialog;
