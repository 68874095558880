import React from 'react';
import useNotifications from '~/context/Notifications';
import Notification from './Notification';

function GlobalNotification() {
  const { showNotification, notificationMessage, notificationLevel, closeNotification } =
    useNotifications();

  return (
    <Notification
      type="snack"
      place="tc"
      show={showNotification}
      message={notificationMessage}
      level={notificationLevel}
      onClose={closeNotification}
    />
  );
}

export default GlobalNotification;
