import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { ClientsService } from '~/services/ClientsService';

export default function useGetClientSessionsQuery() {
  const location = useLocation();
  return useQuery({
    queryKey: ['user_sessions.pl', location.pathname],
    queryFn: () => ClientsService.getClientSessions(),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
}
