import utils from '~/layouts/Admin/Admin.utils';
import { useQuery } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import useSessionPlQuery from '../useSessionPlQuery';

export type GenieNavPlData = {
  [title: string]: {
    stat_id: number;
    shortcode: string;
    takes_product: 0 | 1;
    takes_multiple_products: 0 | 1;
    name: string;
    linked: number;
    hidden_fields: number;
    visible_to_client: number;
    title: string;
    visible_to_partner: number;
    links: {
      [key: string]: number;
    };
  };
} | null;

export default function useGenieNavQuery() {
  const { data: sessionData } = useSessionPlQuery();
  const { setTimedNotification } = useNotifications();
  return useQuery({
    queryKey: ['genienav.pl', sessionData?.id, sessionData?.isPartner, sessionData?.nav?.stats],

    queryFn: () => {
      if (sessionData?.id) {
        // not really type-safe, but better than nothing
        return utils.handleGetGenieNav({
          id: sessionData.id,
          isPartner: sessionData.isPartner,
          stats: sessionData.nav.stats,
        }) as Promise<GenieNavPlData>;
      }

      return null;
    },
    onError: (error) => {
      if (error && typeof error === 'object' && 'error' in error) {
        if (typeof error.error === 'string') {
          setTimedNotification('danger', error.error, 5000);
        } else {
          setTimedNotification('danger', 'An error loading data has occurred', 5000);
        }
      }
    },
    enabled: !!sessionData,
    refetchOnWindowFocus: false,
  });
}
