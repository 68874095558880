/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable react/prop-types */
import React from 'react';
import { Box, capitalize } from '@mui/material';
import dayjs from 'dayjs';
import { type MRT_ColumnDef as ColumnType } from 'material-react-table';
import * as XLSX from 'xlsx';

import {
  isCurrencyValue,
  filterOutCurrencies,
  hasCommas,
  filterOutCommas,
  idGenerator,
} from '~/utils/utils';

const styleColumns = {
  ad_text: {
    minWidth: 290,
  },
};

interface NumberFieldObj {
  [key: string]: boolean;
}

// These fields should be cast as numbers for react-table to calculate totals.
// Do not include currency fields. add those to the object below
export const numberFieldsObj: NumberFieldObj = {
  actions: true,
  ad_clicks: true,
  ad_ctr: true,
  cpc_adjustments: true,
  ad_score: true,
  avg_playtime: true,
  average_playtime: true,
  clicks: true,
  clicks_ct: true,
  clicked: true,
  conversion: true,
  conversions: true,
  convr: true,
  conv_rate: true,
  conversion_rate: true,
  convrate: true,
  convRate: true,
  count: true,
  cpc_ctr: true,
  cpc_clicks: true,
  ctr: true,
  viewed_ctr: true,
  conv_pct: true,
  desktop_clicks: true,
  desktop_actions: true,
  desktop_conversion: true,
  ended: true,
  est_viewable_impressions: true,
  est_viewed_impressions: true,
  form_views: true,
  gross_gbp: true,
  gross_usd: true,
  impressions: true,
  imprsn_ct: true,
  in_view_impressions: true,
  leads: true,
  listens_v_2sec: true,
  mobile_clicks: true,
  mobile_actions: true,
  mobile_conversion: true,
  offer_clicks: true,
  page_impressions: true,
  page_loads: true,
  podcast_end_impressions: true,
  podcast_listens: true,
  ppc: true,
  q1: true,
  q2: true,
  q3: true,
  recirculation_clicks: true,
  recirculation_ctr: true,
  recirculation_impressions: true,
  score: true,
  started: true,
  submit_rate: true,
  tablet_clicks: true,
  tablet_actions: true,
  tablet_conversion: true,
  totalcost: true,
  total_impressions: true,
  total_measured_impressions: true,
  valid_clicks: true,
  validated_clicks: true,
  invalid_clicks: true,
  vctr_estimate: true,
  viewable_impressions: true,
  viewability_rate: true,
  video_2sec: true,
  video_impressions: true,
  video_loads: true,
  video_start_impressions: true,
  video_plays: true,
  viewed_impressions_est: true,
  viewed_pct: true,
  vctr: true,
  vtr: true,
  v_2sec: true,
};

// For NumberField values which require minimum fraction digits other than 0 (imported to TableCell)
export const minFractionDigitsMap: { [key: string]: number } = {
  totalCost: 2,
  ad_CTR: 2,
  cpc_CTR: 2,
  vtr: 2,
};
// for fields that you wish to include the currency
const currencyFieldsObj: NumberFieldObj = {
  avg_cpc: true,
  avg_vcpm: true,
  client_spend: true,
  cost: true,
  cost_am: true,
  cpa: true,
  cpc: true,
  cpcv: true,
  cpm: true,
  ecpm: true,
  effective_cpm: true,
  price: true,
  revenue: true,
  spend: true,
  total_revenue: true,
  total_spend: true,
  total: true,
  vcpm: true,
  rpm: true,
};

const imageFields = ['publisher', 'partner', 'creative', 'image', 'img', 'publisher_name'];

export function isCurrencyField(field?: string) {
  if (!field) return false;
  return field.toLowerCase() in currencyFieldsObj;
}

type RecordStringNumber = Record<string, number | string>;

type PartialRecord = Record<string, number | string | undefined | null>;

export type Daum = Record<
  string,
  number | string | null | undefined | React.ReactNode | PartialRecord
>;

type Response = {
  hidden_columns: string;
  field_names: string[];
  total_columns: string;
  chart_columns: string;
  data: Daum[];
  field_display_names: string[];
  type?: string;
  title: string;
};

// Process response from DataService to data for react-table.
export const processTableData = (response: Response, withId = true) => {
  const newData = response.data.map((prop, key) => {
    const dataObject: Daum = {};

    if (withId) {
      dataObject.id = key;
    }

    dataObject.hiddenId = idGenerator();
    for (const field in response.field_names) {
      const fieldName = response.field_names[field];
      const value = prop[fieldName];
      const lowerCaseField = fieldName.toLowerCase();
      // If in numberFields
      if (numberFieldsObj[lowerCaseField] || isCurrencyField(lowerCaseField)) {
        let currentNum = value;
        // we know it's a string | number as fieldName is in numberFields
        if (isCurrencyValue(currentNum as string)) {
          currentNum = filterOutCurrencies(currentNum as string);
        }
        if (hasCommas(currentNum as string)) {
          currentNum = filterOutCommas(currentNum as string);
        }
        currentNum = Number(currentNum);
        // if NaN return original
        if (Number.isNaN(currentNum)) {
          dataObject[fieldName] = value;
        } else {
          dataObject[fieldName] = currentNum || 0;
        }
      } else {
        dataObject[fieldName] = value || '';
      }
      // basic check for html in value
      if (
        typeof value === 'string' &&
        (value.match(/<(div|img)/i) || imageFields.includes(lowerCaseField))
      ) {
        // extract useful parts out of html
        const div = document.createElement('div');
        div.innerHTML = value;
        const img = div.querySelector('img');
        let normalizedValue = '';
        const title = img?.getAttribute('title') || img?.title;
        const alt = img?.getAttribute('alt');
        // img title tends to be more useful than innerText (for example in the case of partners column)
        if (img && (title || alt)) {
          normalizedValue += title || alt;
        } else {
          const { innerText } = div;
          if (innerText) {
            normalizedValue += innerText;
          }
        }
        // assign it to a custom field on the dataObject
        dataObject[`${fieldName}Custom`] = {
          rawValue: value,
          normalizedValue,
          imgSrc: img?.getAttribute('src'),
          imgTitle: title,
          imgAlt: alt,
          innerText: div.innerText,
        };
      }
    }
    return dataObject;
  });
  return newData;
};

const calcColumnTotal = (data?: Array<Daum>, accessor?: string) => {
  if (!accessor) throw new Error('no accessor supplied');
  const total = data?.reduce((sum, row) => {
    if (row[accessor] && !Number.isNaN(Number(row[accessor]))) {
      return sum + Number(row[accessor]);
    }
    return sum;
  }, 0);
  return total ?? 0;
};

const formatColumnTotal = (total: number, accessor: string, currency: string | null) => {
  const isCurrencyVal = isCurrencyField(accessor);
  const formattedTotal = total.toLocaleString(navigator.language, {
    maximumFractionDigits: 2,
    minimumFractionDigits: isCurrencyVal ? 2 : 0,
  });
  return isCurrencyVal && currency ? `${currency}${formattedTotal}` : formattedTotal;
};

export const stringFilterFunctions = [
  'contains',
  'fuzzy',
  'empty',
  'endsWith',
  'startsWith',
  'notEmpty',
];
export const numberFilterFunctions = [
  'equals',
  'fuzzy',
  'notEquals',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'empty',
  'notEmpty',
  'between',
  'betweenInclusive',
];
// Process response from DataService to columns for analytics to feed into react-table.
export const processTableColumns = (
  tableData: Array<Daum>,
  response: Response,
  currency: string | null = null,
) => {
  const data = { ...response };
  const fieldNames = [...data.field_names];
  const fieldDisplayNames = [...data.field_display_names];

  // Map field names in response to column object expected by react-table.
  const tableColumns = fieldNames.map((field, key) => {
    const lowerCaseField = field.toLowerCase();

    // if field_display_names is not empty and field_display_names doesn't already have capitals in it
    // we want to display the field_display_name, as that means it's been customized on the back end
    // and we want to display the customized name
    const header =
      data.field_display_names && fieldDisplayNames[key].toLowerCase() !== fieldDisplayNames[key]
        ? fieldDisplayNames[key]
        : capitalize(field).replace(/_/g, ' ');

    type DataObject = {
      header: string;
      accessor: string;
      accessorKey: string;
      columnTotal: number;
      sortable?: boolean;
      styles?: Record<string, string | number>;
    };
    const dataObject: DataObject & ColumnType<Daum> = {
      header,
      accessor: field,
      accessorKey: field,
      columnTotal: 0,
      minSize: 0,
      size: 0,
    };

    // these three need to be larger than default
    if (['name', 'ad_text', 'text', 'descriptions', 'line1', 'line2'].includes(lowerCaseField)) {
      dataObject.size = 200;
    }
    if (imageFields.includes(lowerCaseField)) {
      //
      dataObject.accessorKey = `${field}Custom.normalizedValue`;
    }
    if (lowerCaseField === 'drilldown') {
      dataObject.enableColumnFilter = false;
      dataObject.enableSorting = false;
      dataObject.enableGlobalFilter = false;
    }
    if (lowerCaseField === 'date') {
      dataObject.sortingFn = (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);
        // we know it's string as date columns are always string
        const dateA = dayjs(valueA as string).format('YYYYMMDD');
        const dateB = dayjs(valueB as string).format('YYYYMMDD');
        if (dateA > dateB) {
          return 1;
        }
        if (dateA < dateB) {
          return -1;
        }
        return 0;
      };
    }
    if (field === 'ad_text' && styleColumns[field]) {
      dataObject.styles = styleColumns[field];
    }
    if (data.total_columns) {
      const totalColumns = data.total_columns.split(',');
      if (totalColumns.includes((key + 1).toString())) {
        dataObject.Footer = ({ column, footer, table }) => {
          const pageTotal = table
            .getPaginationRowModel()
            .rows.reduce((sum, row) => sum + Number(row.getValue<number>(column.id)), 0);
          const allTotal = calcColumnTotal(tableData, field);
          const allTotalInCurrentPage = pageTotal?.toFixed?.(2) === allTotal?.toFixed?.(2);
          return (
            <Box data-testid={`total_${column.columnDef.accessorKey}`}>
              <div>
                {allTotalInCurrentPage
                  ? null
                  : `Current Page Total: ${formatColumnTotal(
                      pageTotal,
                      column.columnDef.accessorKey as string,
                      currency,
                    )}`}
              </div>
              <div>{`Total: ${formatColumnTotal(
                allTotal,
                column.columnDef.accessorKey as string,
                currency,
              )}`}</div>
            </Box>
          );
        };
      }
      if (numberFieldsObj[lowerCaseField] || isCurrencyField(lowerCaseField)) {
        dataObject.columnFilterModeOptions = numberFilterFunctions;
        dataObject.filterFn = 'equals';
      } else {
        // is string based
        dataObject.columnFilterModeOptions = stringFilterFunctions;
        dataObject.filterFn = 'contains';
      }
    }
    return dataObject;
  });
  return tableColumns;
};

export const downloadCSV = (
  response: string,
  fromDate: string,
  toDate: string,
  csvName: string,
) => {
  const url = window.URL.createObjectURL(
    new Blob([`\ufeff${response}`], { type: 'text/csv; charset=utf-8' }),
  );
  const link = document.createElement('a');
  link.href = url;
  const fileName = `${`${csvName + fromDate}_${toDate}`}.csv`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const processXlsxSheetData = (response: Daum[]) =>
  response.map((row) => {
    const { Image } = row;
    let imagePath: string | null;
    if (Image && typeof Image === 'string') {
      // extract path from the src attr of the image tag html
      const urlMatch: RegExpMatchArray | null = Image?.match(/src='([^']+)'/);
      imagePath = urlMatch ? urlMatch[1] : null;

      return {
        ...row,
        Image: `dianomi.com${imagePath}`,
      };
    }
    return row;
  });

export const downloadXLXS = (
  response: Daum[],
  fieldNames: string[],
  fromDate: string,
  toDate: string,
  reportName: string,
) => {
  const sheetData = processXlsxSheetData(response);
  const worksheet = XLSX.utils.json_to_sheet(sheetData, { header: fieldNames });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet 1');

  // XLSX (SheetJS) have a writeFile method that does the same as writeFileXLSX but will attempt to force a client-side download
  // on the browser. This appears to break our ci build by exceeding the system heap memory limit.
  // We'll want to avoid using the writeFile method for now (13/09/24). For writing other formats please use
  // XLSX.write. Note you may need to trigger the download in this instance.
  return XLSX.writeFileXLSX(workbook, `${`${reportName + fromDate}_${toDate}`}.xlsx`);
};

type ChartData = {
  data: Array<RecordStringNumber>;
  xDataKey: string;
  yDataKey: string;
  chartType: 'line' | 'bar' | 'pie';
} | null;

export const getChartData = (analyticsData: Response) => {
  let chartData: ChartData = {
    data: [],
    xDataKey: '',
    yDataKey: '',
    chartType: 'line',
  };
  if (analyticsData.type && analyticsData.type.toLowerCase() !== 'datatable') {
    chartData.data = analyticsData.data as Array<RecordStringNumber>;
    if (analyticsData.chart_columns) {
      const chartColumns = analyticsData.chart_columns
        .split(',')
        .map((columnNumber) => Number(columnNumber) - 1);
      chartData.xDataKey = analyticsData.field_names[chartColumns[0]];
      chartData.yDataKey = analyticsData.field_names[chartColumns[1]];
    } else {
      [chartData.xDataKey, chartData.yDataKey] = analyticsData.field_names;
    }
    switch (analyticsData.type.toLowerCase()) {
      case 'linechart':
        chartData.chartType = 'line';
        break;
      case 'barchart':
        chartData.chartType = 'bar';
        break;
      case 'piechart':
        chartData.chartType = 'pie';
        break;
      default: {
        chartData.chartType = 'line';
      }
    }
  } else {
    chartData = null;
  }

  return chartData;
};

type Stat = {
  shortcode: string;
  takes_product: number;
  takes_multiple_products: 1 | 0;
  name: string;
  linked: number;
  hidden_fields: number;
  visible_to_client: number;
  title: string;
  visible_to_partner: number;
  periods?: Record<string, string>;
  links?: Record<string, string | number>;
};

const timelineStat: Stat = {
  hidden_fields: 0,
  shortcode: 'timeline',
  takes_product: 1,
  takes_multiple_products: 1,
  visible_to_client: 0,
  name: 'Activity Over Time',
  linked: 0,
  visible_to_partner: 0,
  title: 'Activity Over Time',
};

const headlineStat: Stat = {
  hidden_fields: 0,
  shortcode: 'headline',
  takes_product: 1,
  takes_multiple_products: 1,
  visible_to_client: 0,
  name: 'Headline',
  title: 'Headline',
  linked: 0,
  visible_to_partner: 0,
};

const headlineTzStat: Stat = {
  hidden_fields: 0,
  shortcode: 'headline_tz',
  takes_product: 1,
  takes_multiple_products: 1,
  visible_to_client: 0,
  name: 'Headline',
  title: 'Headline',
  linked: 0,
  visible_to_partner: 0,
};

type StatsObject = Record<string, Stat>;

export const getDefaultStat = (stats?: StatsObject | null, statOverride?: string) => {
  if (!stats) {
    return timelineStat;
  }
  if (statOverride === 'timeline') {
    return timelineStat;
  }
  if (statOverride === 'headline') {
    return headlineStat;
  }
  if (statOverride === 'headline_tz') {
    return headlineTzStat;
  }
  const productLabels = Object.keys(stats || {}).sort();
  const statOverrideFound = Object.values(stats || {}).find(
    (stat) => stat.shortcode === statOverride,
  );
  let stat = null;
  if (statOverrideFound) {
    stat = statOverrideFound;
  } else if (productLabels.includes('Publisher Account Performance')) {
    stat = stats['Publisher Account Performance'];
  } else if (productLabels.includes('Partner Revenue Over Time')) {
    stat = stats['Partner Revenue Over Time'];
  } else {
    stat = stats?.[productLabels?.[0]];
    for (const label of productLabels) {
      stat = stats[label];
      if (stat.linked === 0) {
        break;
      }
    }
  }

  return stat;
};

export function getCurrentStat(navData?: StatsObject | null, filter?: string) {
  if (!navData || !filter) return null;
  // All clients have access to Timeline stat (for overview page)
  if (filter === 'timeline') {
    return timelineStat;
  }
  return Object.values(navData || {}).find(
    (stat) => stat.shortcode?.toString() === filter?.toString(),
  );
}

export function getAvailablePeriods(stat?: Stat | null) {
  return Object.keys(stat?.periods || {});
}

export function getAvailablePeriod(periods: Array<string>, currentPeriodFilter: string) {
  return periods.find((period) => period === currentPeriodFilter);
}
