import React from 'react';
import useWindowSize from '~/hooks/useWindowSize';
import { Skeleton, Grid, SkeletonProps } from '@mui/material';

const MenuSkeleton = (props?: SkeletonProps) => {
  const { isMobile } = useWindowSize();
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px',
      }}
    >
      {!isMobile && (
        <Skeleton
          variant="rectangular"
          width="90%"
          height={40}
          sx={{ borderRadius: 2, marginBottom: 1, ...props?.sx }}
        />
      )}
      <Skeleton
        variant="rectangular"
        width="90%"
        height={isMobile ? 400 : 300}
        sx={{
          borderRadius: 2,
          marginBottom: 4,
          ...props?.sx,
        }}
      />
    </Grid>
  );
};

export default MenuSkeleton;
