/* eslint-disable no-use-before-define */
import * as React from 'react';

/**
 * code stolen from
 * https://twitter.com/cpojer/status/1730141247900459166?t=64oKpbBaMYx2ygARkP5zqw&s=03
 * basically, we're replacing React.lazy with a custom implementation that
 * catches errors, attempts to do a window.location.reload() the first time so if the error
 * is due to a new build, it will be fixed on the next reload, and then renders an ErrorPage
 */
export default (function lazy(factory) {
  return React.lazy(() => factory().catch(importErrorHandler));
} as typeof React.lazy);

function importErrorHandler(error: string): { default: React.ComponentType<unknown> } {
  // Get the last reload time from local storage and the current time
  const timeStr = sessionStorage.getItem('last-reload');
  const time = timeStr ? Number(timeStr) : null;
  const now = Date.now();

  // If the last reload time is more than 10 seconds ago, reload the page
  const isReloading = !time || time + 10_000 < now;
  if (isReloading) {
    // eslint-disable-next-line no-console
    console.log('New version for this module found. Reloading ...');
    sessionStorage.setItem('last-reload', String(now));
    window.location.reload();
    return { default: () => null };
  }

  // We let ErrorBoundary handle the error
  throw new Error(error);
}
