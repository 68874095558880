import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import { ClientsService } from '~/services/ClientsService';
import { newProfileInfoType, Data, AccountMutationTypes, AccountState } from './types';

const updateProfileInfo = async (newProfileInfo: newProfileInfoType) => {
  const data: Data = await ClientsService.updateProfileInfo(newProfileInfo);
  if (!data) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: `Error updating default account`,
        file: 'ClientsSelector.js',
        error: 'no data on response',
        fn: 'updateProfileInfo()-01',
      });
      Sentry.captureException(new Error('updateProfileInfo()'));
    });
    throw new Error('Error updating default account');
  }
  if ('error' in data) {
    throw new Error(data.error);
  }
  return data;
};

export const useSetDefaultAccountMutation = () => {
  const { setNotification } = useNotifications();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ clientId, isPartner, isChecked }: AccountMutationTypes) => {
      let type = isPartner ? 'partner' : 'client';
      if (!isChecked) {
        type = 'none';
      }
      const newProfileInfo = { defaultAccountInfo: { id: clientId, type } };
      return updateProfileInfo(newProfileInfo);
    },
    onMutate: async ({ clientId, isPartner, isChecked }) => {
      await queryClient.cancelQueries(['userProfile']);
      let type = isPartner ? 'partner' : 'client';
      if (!isChecked) {
        type = 'none';
      }
      const newProfileInfo = { id: clientId, type };
      queryClient.setQueryData(['userProfile'], (oldData: AccountState) => ({
        ...oldData,
        default_account: newProfileInfo,
      }));
      return newProfileInfo;
    },
    onError: ({ error }) => {
      setNotification(true, 'danger', `${error.message}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['userProfile']);
    },
  });
};
