import { perms } from '~/routing/perms';
import { SessionPlQueryReturnData } from './ClientServiceTS';
import { NavRoute } from '../layouts/Admin/RoutePermissions';

export function insightsNav(insightRoutes: SessionPlQueryReturnData['insights_nav']) {
  const nav = insightRoutes
    .map(
      (insight) =>
        ({
          path: `/insights/${insight.path}`,
          name: insight.name,
          perm: perms.insights,
          live: true,
          paramsToKeep: ['timeframe', 'country'],
        }) satisfies NavRoute,
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return nav;
}
